import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosHorreo } from '@/helpers/api';

export default createAsyncThunk(
  'user/login',
  async (hubId, { __, ___, rejectWithValue }) => {
    try {
      const { data } = await axiosHorreo.post(`/api/stock/employees/${hubId}`);
      const { result } = data;

      if (result.http_status === 200) {
        return result.employees;
      }

      return rejectWithValue('Utilisateurs introuvables pour ce hub');
    } catch (e) {
      console.log({ e });
      return rejectWithValue('Utilisateurs introuvables pour ce hub');
    }
  }
);
