import { createSlice } from '@reduxjs/toolkit';
import { validateScan } from '@/redux/barcode/barcode.actions';

const initialState = {
  value: '',
  isScanValid: undefined,
  warning: false,
  type: undefined,
  message: undefined,
  secondaryMessage: undefined
};

const barcodeSlice = createSlice({
  name: 'barcode',
  initialState,
  reducers: {
    resetIsScanValid: (state) => {
      state.value = '';
      state.isScanValid = undefined;
      state.message = undefined;
      state.secondaryMessage = undefined;
    },
    resetWarning: (state) => {
      state.warning = false;
    }
  },
  extraReducers(builder) {
    builder.addCase(validateScan.fulfilled, (state, { payload }) => {
      state.value = payload?.value;
      state.isScanValid = payload?.isScanValid;
      state.warning = payload?.warning;
      state.type = payload?.type;
      state.message = payload?.message;
      state.secondaryMessage = payload?.secondaryMessage;
    });
  }
});

export const { resetIsScanValid, resetWarning } = barcodeSlice.actions;

export default barcodeSlice.reducer;
