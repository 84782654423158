import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  getBatch,
  closeBatch,
  getBatchs
} from '@/redux/restock/restock.actions';
import { selectUser } from '@/redux/user/user.slice';
import {
  Button,
  FlashScreen,
  Input,
  Loader,
  ScanContainer
} from '@/components';
import { updateRestockStep, resetRestock } from '@/redux/restock/restock.slice';
import { resetLocalStorage } from '@/helpers/helpers';
import { validateScan } from '@/redux/barcode/barcode.actions';

function BatchDetails() {
  const param = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id: userID } = useSelector(selectUser);
  const { currentBatch, batchs, restockStep } = useSelector(
    (state) => state.restock
  );
  const savedProductIndex = JSON.parse(
    localStorage.getItem(`productIndex_${userID}`)
  );

  const savedStep = JSON.parse(localStorage.getItem(`restockStep_${userID}`));

  // product to store
  const productToStore = JSON.parse(
    localStorage.getItem(`productsStored_${userID}`)
  );

  // products_name of products not stored
  const productNotStored = JSON.parse(
    localStorage.getItem(`productsMag_${userID}`)
  )?.map((product) => product.product_name);

  // get all products save in the local storage
  const productsMag = JSON.parse(localStorage.getItem(`productsMag_${userID}`));

  const [productIndex, setProductIndex] = React.useState(
    savedProductIndex || 0
  );
  const [redirectUserToMagZone, setRedirectUserToMagZone] = useState(false);
  const [requestedQty, setRequestedQty] = useState(null);
  const [inputtedQty, setInputtedQty] = useState(null);
  const [qtyError, setQtyError] = useState(false);
  const { isScanValid, message } = useSelector((state) => state.barcode);
  const savedBatchState = JSON.parse(
    localStorage.getItem(`batchState_${userID}`)
  );
  const [currentBatchState, setCurrentBatchState] = useState(
    savedBatchState || currentBatch.data
  );

  useEffect(() => {
    if (currentBatchState?.move_lines[productIndex]?.requested_qty) {
      setRequestedQty(currentBatchState.move_lines[productIndex].requested_qty);
    }
  }, [productIndex]);

  useEffect(() => {
    if (
      currentBatchState?.move_lines[productIndex]?.requested_qty &&
      requestedQty === null
    ) {
      setRequestedQty(currentBatchState.move_lines[productIndex].requested_qty);
    }
  }, [currentBatchState]);

  useEffect(() => {
    if (!currentBatch.data)
      dispatch(getBatch({ batchId: param.batchId, employeeId: userID }));
  }, [param.batchId, userID, productIndex]);

  useEffect(() => {
    if (currentBatch.status === 'succeeded') {
      dispatch(getBatchs(userID));
    }
  }, [currentBatch]);

  useEffect(() => {
    setCurrentBatchState(currentBatch.data);
  }, [currentBatch.data, batchs.data]);

  // Saving productIndex to localStorage every time it changes
  useEffect(() => {
    localStorage.setItem(
      `productIndex_${userID}`,
      JSON.stringify(productIndex)
    );
  }, [productIndex]);

  useEffect(() => {
    if (savedStep) {
      dispatch(updateRestockStep({ restockStep: savedStep }));
    }
  }, []);

  const handleCloseBatch = async () => {
    const res = await dispatch(
      closeBatch({ batchId: param.batchId, employeeId: userID })
    );
    if (res?.payload?.http_status !== 200) {
      dispatch(
        validateScan({
          type: 'restock_product',
          value: undefined,
          expectedValue: undefined,
          restockStep: 'close_batch'
        })
      );
    } else {
      resetLocalStorage(userID);
      resetRestock();
      localStorage.setItem(
        `restockStep_${userID}`,
        JSON.stringify(restockStep)
      );

      dispatch(getBatchs(userID));

      navigate('/restock');
    }
  };

  // store restockStep in localStorage in useEffect
  useEffect(() => {
    localStorage.setItem(`restockStep_${userID}`, JSON.stringify(restockStep));
  }, [restockStep]);

  const handleValueChange = (onChangeValue) => {
    const updatedBatch = JSON.parse(JSON.stringify(currentBatchState));

    if (updatedBatch.move_lines && updatedBatch.move_lines[productIndex]) {
      updatedBatch.move_lines[productIndex].qty_done = Number(onChangeValue);
      setCurrentBatchState(updatedBatch);
    }
    setInputtedQty(onChangeValue);
  };

  const handleClick = () => {
    const updatedBatchState = JSON.parse(JSON.stringify(currentBatchState));

    if (inputtedQty <= requestedQty) {
      if (
        updatedBatchState.move_lines &&
        updatedBatchState.move_lines[productIndex]
      ) {
        // Create a new object from the original
        const updatedLine = { ...updatedBatchState.move_lines[productIndex] };

        // Update the qty_done value

        updatedLine.qty_done = Number(requestedQty);

        // Replace the original object with our new, modified one
        updatedBatchState.move_lines[productIndex] = updatedLine;

        // Update the state
        setCurrentBatchState(updatedBatchState);

        // Save to localStorage
        localStorage.setItem(
          `batchState_${userID}`,
          JSON.stringify(updatedBatchState)
        );

        // save to localstorage all MAG products
        localStorage.setItem(
          `productsMag_${userID}`,
          JSON.stringify(updatedBatchState.move_lines)
        );
      }

      if (productIndex + 1 < currentBatchState.move_lines?.length) {
        setProductIndex(productIndex + 1);
        dispatch(updateRestockStep({ restockStep: 'scan_product' }));
      }
      if (productIndex + 1 === currentBatchState.move_lines?.length) {
        setProductIndex(0);
        setRedirectUserToMagZone(true);
      }
    } else {
      setQtyError(true);
      setTimeout(() => {
        setQtyError(false);
      }, 3000);
    }
  };

  const handleClickMagZone = () => {
    setRedirectUserToMagZone(false);
    dispatch(updateRestockStep({ restockStep: 'list_products_magzone' }));
  };

  const resolveExpectedValue = () => {
    if (
      restockStep === 'scan_product' ||
      restockStep === 'quantities_input' ||
      restockStep === 'scan_product_magzone'
    )
      return currentBatchState.move_lines[productIndex]?.product_barcode;

    if (restockStep === 'list_products_magzone') return productsMag;

    if (restockStep === 'scan_mag') return productToStore.dest_barcode;

    return currentBatchState.move_lines[productIndex]?.dest_name;
  };

  const resolveButton = () => {
    if (redirectUserToMagZone)
      return (
        <Button
          onClick={handleClickMagZone}
          isPrimary
          title={t('views.restock.go_magzone')}
        />
      );

    if (restockStep === 'quantities_input')
      return (
        <Button
          onClick={handleClick}
          isPrimary
          title={t('views.restock.validate_quantity')}
        />
      );
    if (restockStep === 'scan_mag')
      return <div className="u-scan">{t('views.restock.scan_mag')}</div>;

    if (restockStep === 'list_products_magzone')
      return <div className="u-scan">{t('views.restock.scan_cart')}</div>;

    if (restockStep === 'scan_product')
      return <div className="u-scan">{t('views.restock.scan_product')}</div>;

    if (restockStep === 'job_completed')
      return (
        <Button
          onClick={handleCloseBatch}
          isPrimary
          title={t('views.restock.close_batch')}
        />
      );

    return (
      <div className="u-scan">{t('views.restock.scan_product_magzone')}</div>
    );
  };

  // order products by ascending order
  const list = currentBatchState?.move_lines?.map((product) => [
    product.dest_name.substr(4),
    product.product_name
  ]);
  const sortedUniqueSequences = Array.from(
    new Set(list?.map((item) => item[0]))
  ).sort((a, b) => {
    const [aFirstPart, aSecondPart] = a.split('-');
    const [bFirstPart, bSecondPart] = b.split('-');

    if (aFirstPart !== bFirstPart) {
      return aFirstPart.localeCompare(bFirstPart);
    }
    return aSecondPart.localeCompare(bSecondPart);
  });

  if (currentBatch?.status !== 'succeeded' || !currentBatchState?.batch_id)
    return (
      <div className="u-page-center">
        <Loader />
      </div>
    );

  if (currentBatchState?.move_lines?.length === 0) {
    return (
      <div className="u-page-center">{t('views.restock.no_move_line')}</div>
    );
  }

  return (
    <div className="batch-details">
      <ScanContainer
        type="restock_product"
        expectedValue={resolveExpectedValue()}
      >
        {!redirectUserToMagZone &&
          restockStep !== 'list_products_magzone' &&
          restockStep !== 'job_completed' && (
            <div className="header-wrapper">
              <span>
                {t('views.restock.batch')} {param.batchId}{' '}
                {restockStep === 'scan_product' ||
                restockStep === 'quantities_input'
                  ? batchs.data &&
                    batchs.data?.length > 0 &&
                    t(
                      `views.restock.batch_state.${
                        batchs?.data?.find(
                          (batch) => batch.id === parseInt(param.batchId)
                        ).state
                      }`
                    )
                  : t('views.restock.product_to_mag')}
              </span>
              <span>
                {t('views.restock.product')}
                {restockStep === 'scan_mag' &&
                currentBatchState.move_lines &&
                productNotStored
                  ? ` ${
                      currentBatchState.move_lines.length -
                      productNotStored.length
                    } / `
                  : ` ${productIndex + 1} / `}
                {currentBatchState.move_lines?.length}
              </span>
            </div>
          )}

        {restockStep === 'scan_product' && !redirectUserToMagZone && (
          <div className="job-infos">
            <span className="job-infos_header">
              {t('views.restock.product_source')}
            </span>
            <Input
              editable={false}
              initialValue={
                currentBatchState?.move_lines[productIndex]?.source_name
              }
            />
            <span className="job-infos_header">
              {t('views.restock.product_available')}
            </span>
            <Input
              editable={false}
              initialValue={
                currentBatchState?.move_lines[productIndex]?.product_name
              }
            />
            <span className="job-infos_header">
              {t('views.restock.barcode')}
            </span>
            <Input
              editable={false}
              initialValue={
                currentBatchState?.move_lines[productIndex]?.product_barcode
              }
            />
          </div>
        )}

        {restockStep === 'quantities_input' && !redirectUserToMagZone && (
          <div className="job-infos">
            <span className="job-infos_header">
              {t('views.restock.quantity_to_add')}
            </span>
            <Input
              editable={false}
              initialValue={
                currentBatchState?.move_lines[productIndex]?.requested_qty
              }
            />

            <span className="job-infos_header">
              {t('views.restock.quantity_added')}
            </span>
            <Input
              editable
              initialValue={requestedQty}
              onChange={handleValueChange}
              type="number"
            />
          </div>
        )}

        {restockStep === 'scan_product_magzone' && (
          <div className="job-infos">
            <span className="job-infos_header">
              {t('views.restock.product_to_move')}
            </span>

            <Input
              editable={false}
              initialValue={
                currentBatchState?.move_lines[productIndex]?.product_name
              }
            />
            <span className="job-infos_header">
              {t('views.restock.product_destination')}
            </span>
            <Input
              editable={false}
              initialValue={
                currentBatchState?.move_lines[productIndex]?.dest_name
              }
            />
          </div>
        )}

        {/* List all products in ascending order */}
        {restockStep === 'list_products_magzone' && (
          <div>
            <div className="button-container">{resolveButton()}</div>

            <div className="list-products">
              {sortedUniqueSequences?.map((sequence) => (
                <div>
                  <h2>MAG-{sequence}</h2>
                  <ul style={{ marginBottom: '25px' }}>
                    {list
                      .filter((item) => item[0] === sequence)
                      ?.map((item) => (
                        <li
                          style={{
                            listStyle: 'none',
                            margin: '0 0 15px -15px'
                          }}
                        >
                          {!productNotStored?.includes(item[1]) ? '✅ ' : '🔳 '}{' '}
                          {item[1]}
                        </li>
                      ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        )}

        {restockStep === 'scan_mag' && !redirectUserToMagZone && (
          <div className="job-infos">
            <span className="job-infos_header">
              {t('views.restock.product_to_move')}
            </span>

            <Input
              editable={false}
              initialValue={productToStore?.product_name}
            />
            <span className="job-infos_header">
              {t('views.restock.product_destination')}
            </span>
            <Input editable={false} initialValue={productToStore?.dest_name} />
            <span className="job-infos_header">
              {t('views.restock.barcode')}
            </span>
            <Input
              editable={false}
              initialValue={productToStore?.dest_barcode}
            />
          </div>
        )}

        {redirectUserToMagZone && (
          <div className="restock-info-container">
            {t('views.restock.redirect_user_magzone')}
          </div>
        )}

        {restockStep === 'job_completed' && (
          <div className="restock-info-container">
            {t('views.restock.job_completed')}
          </div>
        )}

        {(restockStep === 'quantities_input' ||
          restockStep === 'scan_product_magzone' ||
          restockStep === 'scan_mag' ||
          restockStep === 'scan_product' ||
          restockStep === 'job_completed' ||
          redirectUserToMagZone) && (
          <div className="button-container">{resolveButton()}</div>
        )}

        {isScanValid === false && (
          <FlashScreen type="error" message={t(message)} />
        )}
        {qtyError && (
          <FlashScreen type="error" message={t('views.restock.qty_error')} />
        )}
      </ScanContainer>
    </div>
  );
}

export default BatchDetails;
