import { useEffect } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ScanContainer, FlashScreen } from '@/components';
import { toggleModal } from '@/redux/modal/modal.slice';
import { ResumeRackSupplyModal } from '..';
import { selectIsLittleHub } from '@/redux/user/user.slice';

const ScanRack = () => {
  const { t } = useTranslation();

  const { selectedRound } = useSelector((state) => state.round);
  const { isScanValid, warning } = useSelector((state) => state.barcode);
  const { mobileRack } = useSelector((state) => state.supplyProducts);
  const isLittleHub = useSelector(selectIsLittleHub);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedRound.id && !isLittleHub) {
      navigate('/supply');
    }
  }, []);

  useEffect(() => {
    if (warning) {
      dispatch(toggleModal(true));
    }
  }, [warning]);

  let view = null;

  if (isScanValid === undefined) {
    view = (
      <div className="u-page-center">
        {!isLittleHub && (
          <span>
            {t('views.scan_rack.view_text')}{' '}
            <strong>{selectedRound.name}</strong>{' '}
          </span>
        )}
        <br />
        <span className="u-scan">{t('views.scan_rack.view_text_scan')}</span>
        <br />
        {!isLittleHub && (
          <Link to="/supply">
            <span className="v-previous-product__title">
              {t('views.scan_rack.change_batch')}
            </span>
          </Link>
        )}
      </div>
    );
  } else if (isScanValid) {
    view = <Navigate replace to={`${mobileRack}`} />;
  } else if (warning) {
    view = <ResumeRackSupplyModal />;
  } else {
    view = <FlashScreen type="error" message="Étagère inconnue" />;
  }

  return <ScanContainer type="rack">{view}</ScanContainer>;
};

export default ScanRack;
