import { BackButton, ScanContainer } from '@/components';
import WarehouseFlowsContent from '../WarehouseFlowsContent/WarehouseFlowsContent';

function WarehouseFlows() {
  return (
    <div className="warehouse-flows-wrapper">
      <ScanContainer type="warehouse-flows">
        <div className="back-button-wrapper">
          <BackButton />
        </div>
        <WarehouseFlowsContent />
      </ScanContainer>
    </div>
  );
}

export default WarehouseFlows;
