import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validateScan } from '@/redux/barcode/barcode.actions';

const ScanContainer = ({ type, children, expectedValue }) => {
  const dispatch = useDispatch();
  const { restockStep } = useSelector((state) => state.restock);

  useEffect(() => {
    const eventListener = (e) => {
      dispatch(
        validateScan({ type, value: e.detail.data, expectedValue, restockStep })
      );
    };

    // onScan event is triggered from the device scanner upon a scan, and is catched here
    document.addEventListener('onScan', eventListener);

    return () => {
      document.removeEventListener('onScan', eventListener);
    };
  }, [expectedValue, restockStep, type]);

  return children;
};

export default ScanContainer;
