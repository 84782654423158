import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toggleModal } from '@/redux/modal/modal.slice';
import { Button, Modal } from '@/components';
import { axios } from '@/helpers/api';
import { resetIsScanValid, resetWarning } from '@/redux/barcode/barcode.slice';
import { selectUser } from '@/redux/user/user.slice';

const ResumeRackSupplyModal = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { hubId } = useSelector(selectUser);

  const [rackInfo, setRackInfo] = useState({});

  const { value: rackCode } = useSelector((state) => state.barcode);

  const handleCancelClick = () => {
    dispatch(resetIsScanValid());
    dispatch(resetWarning());
    dispatch(toggleModal(false));
  };

  useEffect(() => {
    async function getRackInfo() {
      const { data } = await axios.get(
        `/picking/hub/${hubId}/provision/status`
      );
      setRackInfo(data[rackCode]);
    }
    getRackInfo();
  }, []);

  console.log({ rackInfo });
  return (
    <Modal>
      <div className="v-cancel-prev-modal">
        <div className="v-cancel-prev-modal__header">
          {t('views.resume_rack_supply_modal.header')}{' '}
          <strong>{rackInfo?.trip}</strong>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            className="v-cancel-prev-modal__ok-btn"
            title="OK"
            onClick={handleCancelClick}
            isPrimary
          />
        </div>
      </div>
    </Modal>
  );
};

export default ResumeRackSupplyModal;
