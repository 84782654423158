/* eslint-disable no-param-reassign */
import { createSelector, createSlice, current } from '@reduxjs/toolkit';
import { sortProductsByLocation } from '@/helpers/helpers';
import { getGroceriesByPackage } from './fillProducts.actions';

const initialState = {
  data: {},
  status: 'idle',
  error: null
};

const fillProductsSlice = createSlice({
  name: 'fillProducts',
  initialState,
  reducers: {
    getProductsTofill: (state, { payload }) => {
      console.log(payload);
    },
    resetFillProductsData: (state) => {
      state.data = {};
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getGroceriesByPackage.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getGroceriesByPackage.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        // state.productToScan = payload;
        state.data = payload;
      })
      .addCase(getGroceriesByPackage.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.data = {};
      });
  }
});

function selectProducts(state) {
  return state.fillProducts.data;
}

export const { getProductsTofill, resetFillProductsData } =
  fillProductsSlice.actions;

export default fillProductsSlice.reducer;
