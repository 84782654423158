import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Buffer } from 'buffer';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BarcodeIconSvg } from '@/assets/barcode_icon.svg';
import { ReactComponent as HelpIcon } from '@/assets/help.svg';
import { ReactComponent as PrintIconBlack } from '@/assets/print_black.svg';
import { ReactComponent as DoneIcon } from '@/assets/done.svg';
import { resetIsScanValid } from '@/redux/barcode/barcode.slice';
import { FlashScreen, ScanContainer } from '@/components';
import PrintContainer from '@/components/PrintContainer/PrintContainer';

const PrintLabel = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [width, setWidth] = useState('');
  const [printing, setPrinting] = useState(false);
  const [printed, setPrinted] = useState(false);
  const [hasHubSettings, setHasHubSettings] = useState(false);

  const { data } = useSelector((state) => state.basket);

  const { isScanValid, message } = useSelector((state) => state.barcode);

  const sendToPrinter = () => {
    if (window.messageHandler) {
      const encodedData = Buffer.from(JSON.stringify(data)).toString('base64');
      window.messageHandler.postMessage(encodedData);
    }
  };

  const getHubSettings = () => {
    if (window.hubSettingsGetter) {
      // HubSettings API call
      const fetchedHubSettings = {
        printerIP: '192.168.8.100',
        labelTemplateID: 0
      };

      const encodedData = Buffer.from(
        JSON.stringify(fetchedHubSettings)
      ).toString('base64');
      window.hubSettingsGetter.postMessage(encodedData);
    }
    setHasHubSettings(true);
  };

  const print = () => {
    sendToPrinter();
    setPrinting(true);
    setTimeout(() => {
      setWidth('wider');
    }, 100);
    setTimeout(() => {
      setPrinting(false);
      setWidth('');
      setPrinted(true);
    }, 2250);
  };

  useEffect(() => {
    if (!hasHubSettings) getHubSettings();
  }, [hasHubSettings]);

  useEffect(() => {
    if (data?.name && data?.addresse1 && data?.orderID && data?.packageNumber)
      print();
  }, [data]);

  useEffect(() => {
    if (printed) setPrinted(false);
  }, [isScanValid]);

  return (
    <PrintContainer type="filledBasket">
      <div className="u-page-center">
        <div className="print-header">
          <Link to="faq">
            <HelpIcon />
          </Link>
        </div>
        {isScanValid === undefined && (
          <div className="print-scan-container">
            <ScanContainer type="filledBasket">
              <BarcodeIconSvg />
              <h1>{t('views.print_label.scan_package')}</h1>
            </ScanContainer>
          </div>
        )}

        {isScanValid === true && (
          <div className="infos-container">
            <h2>{t('views.print_label.package_infos')}</h2>
            <span>
              #{data.packageNumber} {data.packageSequence}
            </span>

            <div className="info-slug first">
              <span>{t('views.print_label.order_number')}</span>
              <h2>{data.orderID}</h2>
            </div>
            <div className="info-slug">
              <span>{t('views.print_label.name')}</span>
              <h2>{data.name}</h2>
            </div>
            <div className="info-slug">
              <span>{t('views.print_label.address')}</span>
              <h2>
                {data.addresse1}
                <br />
                {`${data.zipcode} ${data.city}`}
              </h2>
            </div>
            <div className="info-slug">
              <span>{t('views.print_label.date')}</span>
              <h2>
                <span className="date-text">{data.date}</span>
                <span className="time-slot-text">{data.deliverySlot}</span>
              </h2>
            </div>

            {printing && !printed && (
              <div className="currently-printing-container">
                <span>{t('views.print_label.printing')}</span>
                <div className="progress-bar-container">
                  <PrintIconBlack />
                  <div className="progress-bar">
                    <div className={`progress-bar-layer ${width}`} />
                  </div>
                </div>
              </div>
            )}

            {printed && (
              <div className="printing-done-container">
                <div
                  type="button"
                  className="print-button"
                  onClick={() => {
                    dispatch(resetIsScanValid());
                    setPrinting(false);
                    setPrinted(false);
                  }}
                >
                  <DoneIcon />
                  <span>{t('views.print_label.next')}</span>
                </div>
                <span
                  onClick={() => {
                    setPrinted(false);
                    setPrinting(false);
                    print();
                  }}
                >
                  {t('views.print_label.print_again')}
                </span>
              </div>
            )}
          </div>
        )}

        {isScanValid === false && (
          <FlashScreen
            type="error"
            message={
              message
                ? t(message)
                : `${t('views.print_label.error_scan_again')}`
            }
          />
        )}
      </div>
    </PrintContainer>
  );
};

export default PrintLabel;
