import api from 'axios';
import { ENVIRONMENTS } from '@/views/Settings/Environment/Environment';

const user = localStorage.getItem('user') || '{}';

let env = localStorage.getItem('env');
if (!env) {
  env = JSON.stringify(ENVIRONMENTS[0]);
  localStorage.setItem('env', env);
}

const { name } = JSON.parse(user);
const { value } = JSON.parse(env);

export const isProd = value === ENVIRONMENTS[0].value;

export const BASE_URL = isProd
  ? `${process.env.REACT_APP_FAIT_MAISON_API_PRODUCTION}/internal`
  : `${process.env.REACT_APP_FAIT_MAISON_API_STAGING}/internal`;

export const axios = api.create({
  baseURL: BASE_URL
});

export const engine = isProd
  ? 'https://api.engine.benebono.co'
  : 'http://localhost';

axios.interceptors.request.use((config) => {
  const KEY = isProd
    ? process.env.REACT_APP_API_KEY_PRODUCTION
    : process.env.REACT_APP_API_KEY_STAGING;

  config.params = {
    // add your default ones
    user: name,
    // spread the request's params
    ...config.params
  };
  config.headers.Authorization = `Bearer ${KEY}`;

  return config;
});

export const BASE_URL_HORREO = isProd
  ? process.env.REACT_APP_HORREO_API
  : process.env.REACT_APP_HORREO_API_STAGING;

export const axiosHorreo = api.create({
  baseURL: BASE_URL_HORREO
});

axiosHorreo.interceptors.request.use((config) => {
  config.data = {
    // add your default ones
    username: process.env.REACT_APP_HORREO_USER_NAME,
    token: process.env.REACT_APP_HORREO_TOKEN,
    // spread the request's params
    ...config.data
  };

  config.headers = {
    'Content-Type': 'application/json',
    ...config.headers
  };

  return config;
});

export const BASE_URL_OPENAPI = isProd
  ? process.env.REACT_APP_OPENAPI_HORREO
  : process.env.REACT_APP_OPENAPI_HORREO_STAGING;

export const openApiHorreo = api.create({
  baseURL: BASE_URL_OPENAPI
});

openApiHorreo.interceptors.request.use((config) => {
  config.auth = {
    username: process.env.REACT_APP_OPENAPI_HORREO_USER_NAME,
    password: process.env.REACT_APP_OPENAPI_KEY
  };

  config.headers = {
    'Content-Type': 'application/json',
    ...config.headers
  };

  return config;
});

axiosHorreo.interceptors.request.use((request) => {
  const KEY = isProd
    ? process.env.REACT_APP_API_KEY_PRODUCTION
    : process.env.REACT_APP_API_KEY_STAGING;

  fetch(`${BASE_URL}/picking/logs`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `Bearer ${KEY}`
    },
    body: JSON.stringify({
      url: request.url,
      method: request.method,
      user: name,
      payload: request.data || {},
      severity: 'request'
    })
  });

  console.log(request);

  return request;
});

axiosHorreo.interceptors.response.use((response) => {
  const KEY = isProd
    ? process.env.REACT_APP_API_KEY_PRODUCTION
    : process.env.REACT_APP_API_KEY_STAGING;

  fetch(`${BASE_URL}/picking/logs`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `Bearer ${KEY}`
    },
    body: JSON.stringify({
      url: response.config.url,
      method: response.config.method,
      user: name,
      payload: response.data || {},
      severity: 'response',
      comment: response?.data?.result?.error_message
    })
  });
  return response;
});
