import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function PrintLabelFaqElement() {
  const { t, i18n } = useTranslation();
  const param = useParams();
  const itemIndex = parseInt(param.item) + 1;
  let contentNumber = 0;

  for (
    let i = 0;
    i18n.exists(`views.print_label.faq_item_${itemIndex}_content_${i + 1}`);
    i += 1
  ) {
    contentNumber += 1;
  }

  const generateFaqItemContents = () => {
    const faqItemContents = [];
    for (let i = 0; i < contentNumber; i += 1) {
      faqItemContents.push(
        <p>{t(`views.print_label.faq_item_${itemIndex}_content_${i + 1}`)}</p>
      );
    }
    return faqItemContents;
  };

  return (
    <div className="faq-container">
      <div className="text-wrapper">
        <h3>{t(`views.print_label.faq_item_${itemIndex}_title`)}</h3>
        <span>{generateFaqItemContents()}</span>
      </div>
    </div>
  );
}

export default PrintLabelFaqElement;
