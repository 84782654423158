import { createSlice } from '@reduxjs/toolkit';
import {
  getPalletId,
  getStockQuantPackageByID,
  getStockQuant,
  getPcbIds,
  getProductName,
  getProductPackaging,
  movePallet,
  supplyProdline,
  movePackaging,
  getQuantIds,
  fillPackage,
  getAliasFromMob
} from './warehouseFlows.actions';

const initialState = {
  warehouseFlowStep: '',
  storedBarcode: '',
  destBarcode: '',
  sourceBarcode: '',
  productName: '',
  qty: 0,
  dosingPcbData: [],
  isLoading: false
};

const setLoadingState = (state, isLoading) => {
  state.isLoading = isLoading;
};

const warehouseFlowsSlice = createSlice({
  name: 'warehouseFlows',
  initialState,
  reducers: {
    resetWarehouseFlows: (state) => {
      state.warehouseFlowStep = '';
      state.storedBarcode = '';
      state.destBarcode = '';
      state.sourceBarcode = '';
      state.productName = '';
      state.qty = 0;
      state.dosingPcbData = [];
      state.isLoading = false;
    },
    updateWarehouseFlowStep: (state, action) => {
      state.warehouseFlowStep = action.payload.warehouseFlowStep;
    },
    updateStoredBarcode: (state, action) => {
      state.storedBarcode = action.payload.storedBarcode;
    },
    updateDestBarcode: (state, action) => {
      state.destBarcode = action.payload.destBarcode;
    },
    updateSourceBarcode: (state, action) => {
      state.sourceBarcode = action.payload.sourceBarcode;
    },
    updateDosingPcbData: (state, action) => {
      state.dosingPcbData = action.payload.dosingPcbData;
    },
    updateProductName: (state, action) => {
      state.productName = action.payload.productName;
    },
    updateProductQty: (state, action) => {
      state.qty = action.payload.qty;
    }
  },
  extraReducers(builder) {
    const asyncActions = [
      getPalletId,
      getStockQuantPackageByID,
      getStockQuant,
      getPcbIds,
      getProductName,
      getProductPackaging,
      movePallet,
      supplyProdline,
      movePackaging,
      getQuantIds,
      fillPackage,
      getAliasFromMob
    ];

    asyncActions.forEach((action) => {
      builder
        .addCase(action.pending, (state) => setLoadingState(state, true))
        .addCase(action.fulfilled, (state) => setLoadingState(state, false))
        .addCase(action.rejected, (state) => setLoadingState(state, false));
    });
  }
});

export const {
  resetWarehouseFlows,
  updateWarehouseFlowStep,
  updateStoredBarcode,
  updateDestBarcode,
  updateSourceBarcode,
  updateDosingPcbData,
  updateProductName,
  updateProductQty,
  sendAliasesToEngine
} = warehouseFlowsSlice.actions;

export default warehouseFlowsSlice.reducer;
