import { createContext, useState, useMemo } from 'react';

const UserContext = createContext();
const PIN_LENGTH = 4;

const UserProvider = ({ children }) => {
  const [user, setUser] = useState({
    id: '',
    pin: new Array(PIN_LENGTH).fill(''),
    lang: 'fr'
  });

  const value = useMemo(() => ({
    user,
    setUser
  }));

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export { UserContext, UserProvider };
