import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

export default function Button({
  title,
  onClick,
  type,
  isPrimary,
  isDisabled,
  isLoading,
  color,
  className,
  variant
}) {
  const buttonStyle =
    variant === 'outlined'
      ? clsx(
          'button',
          `button--${isPrimary ? `primary-${color}` : `secondary-${color}`}`,
          isDisabled &&
            (isPrimary
              ? `button--primary-disabled`
              : `button--secondary-disabled`),
          className && className
        )
      : clsx('button-text', className && className);

  return (
    <button
      className={buttonStyle}
      type={type === 'submit' ? 'submit' : 'button'}
      onClick={onClick}
      disabled={isDisabled || isLoading}
    >
      {title}
    </button>
  );
}

Button.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['outlined', 'text']),
  color: PropTypes.oneOf(['main', 'error']),
  type: PropTypes.oneOf(['button', 'submit']),
  isPrimary: PropTypes.bool,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool
};

Button.defaultProps = {
  variant: 'outlined',
  color: 'main',
  type: 'button'
};
