import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { ScanContainer, FlashScreen } from '@/components';

import { setMode } from '@/redux/mode/mode.slice';
import { resetFillProductsData } from '@/redux/fillProducts/fillProducts.slice';

const ScanBasket = () => {
  const { t } = useTranslation();

  const { isScanValid, warning } = useSelector((state) => state.barcode);
  const { data: modes, selectedMode } = useSelector((state) => state.mode);

  const { data } = useSelector((state) => state.fillProducts);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('check selectedMode', selectedMode);
    if (selectedMode.id !== 'fill') {
      dispatch(setMode(modes.find((m) => m.id === 'fill')));
    }
  }, []);

  let view = null;
  const qtyToScan = data.products?.reduce(
    (prev, curr) => prev + curr.quantity,
    0
  );

  useEffect(() => {
    console.log('useEffect on data change');
    if (qtyToScan === 0) {
      setTimeout(() => {
        dispatch(resetFillProductsData());
      }, 2500);
    }
  }, [data]);

  view = (
    <div className="u-page-center">
      {qtyToScan === 0 && (
        <div>
          {t('views.scan_basket.view_text_1')} <strong>{data.package}</strong>{' '}
          {t('views.scan_basket.view_text_2')} ✅
        </div>
      )}
      <br />
      <span className="u-scan">{t('views.scan_basket.scan')}</span>
    </div>
  );

  if (isScanValid === undefined) {
    console.log('1');
  } else if (isScanValid) {
    // if placeName null and products array is empty => no grocery for this basket

    if (!data.placeName && data?.products?.length === 0) {
      console.log('2');
      return (
        <div className="u-page-center">
          <span style={{ textAlign: 'center' }}>
            {t('views.scan_basket.no_product')} <strong>{data.package}</strong>{' '}
            <br />
            {t('views.scan_basket.next_basket')} ✅ <br /> <br />
          </span>
          <ScanContainer type="basket">
            <span className="u-scan">{t('views.scan_basket.scan')}</span>
          </ScanContainer>
        </div>
      );
    }

    if (warning) {
      console.log('3');
      return <FlashScreen type="error" message={warning} />;
    }

    if (data.placeName && qtyToScan !== 0) {
      console.log('4');
      return <Navigate replace to={`${data.placeName}`} />;
    }
  } else {
    console.log('5');
    view = <FlashScreen type="error" message="Panier inconnu" />;
  }

  console.log('6');
  return <ScanContainer type="basket">{view}</ScanContainer>;
};

export default ScanBasket;
