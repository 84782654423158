import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import InventoryIcon from '@mui/icons-material/Inventory';
import { ReactComponent as BarcodeSvg } from '@/assets/barcode.svg';
import { ScanContainer, FlashScreen } from '@/components';

const ScanProduct = ({ EAN13 }) => {
  const { t } = useTranslation();

  const { isScanValid, warning } = useSelector((state) => state.barcode);

  let view = null;
  if (isScanValid === undefined) {
    view = (
      <div className="v-scan-product v-scan-product--to-scan">
        {t('views.scan_product.product_to_scan')}{' '}
        <div className="v-scan-product__svg">
          <BarcodeSvg />
        </div>
        <small>{EAN13}</small>
      </div>
    );
  } else if (!isScanValid) {
    view = <FlashScreen type="error" message="Produit inconnu" />;
  } else if (warning) {
    view = <FlashScreen type="warning" message="Produit manquant" />;
  } else {
    view = (
      <div className="v-scan-product v-scan-product--scanned">
        {t('views.scan_product.product_scanned')}
        <div className="v-scan-product__svg">
          <InventoryIcon />
        </div>
      </div>
    );
  }

  return <ScanContainer type="product">{view}</ScanContainer>;
};

export default ScanProduct;
