import React from 'react';
import { Link } from 'react-router-dom';

const SelectOption = ({ option, link, onSelect, name, isDisabled }) => (
  <Link to={link}>
    <button
      type="button"
      className={`c-select-option__item ${
        isDisabled && 'c-select-option__item--disabled'
      }`}
      key={option.id}
      onClick={() => onSelect(option)}
      disabled={isDisabled}
    >
      {name}
    </button>
  </Link>
);

export default SelectOption;
