import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectUser, setUserFromSession } from '@/redux/user/user.slice';
import { isEmpty } from '@/helpers/helpers';

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isSignInPage = location.pathname === '/signin';
  const user = useSelector(selectUser);

  useEffect(() => {
    const data = localStorage.getItem('user');
    if (data) {
      dispatch(setUserFromSession(JSON.parse(data)));
    } else {
      navigate('/signin');
    }
  }, []);

  if (isEmpty(user) && !isSignInPage) return null;

  return children;
};

export default Layout;
