import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { validateScan } from '@/redux/barcode/barcode.actions';

const PrintContainer = ({ type, children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const eventListener = (e) => {
      if (e.detail.data.errorString === 'Print_error') {
        dispatch(validateScan({ type, value: e.detail.data.errorString }));
      }
    };
    document.addEventListener('onPrintError', eventListener);

    return () => {
      document.removeEventListener('onPrintError', eventListener);
    };
  }, []);

  return children;
};

export default PrintContainer;
