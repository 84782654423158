import React, { useState, useEffect } from 'react';

function Input({ editable, initialValue, onChange, type }) {
  const [value, setValue] = useState(initialValue || '');

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleInputChange = (event) => {
    setValue(event.target.value);
    onChange(event.target.value);
  };

  return (
    <input
      className="custom-input"
      type={type ?? 'text'}
      value={value}
      onChange={handleInputChange}
      readOnly={!editable}
    />
  );
}

export default Input;
