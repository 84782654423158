import { useEffect, useState } from 'react';

const days = 'dim._lun._mar._mer._jeu._ven._sam.'.split('_');
const months =
  'jan._fév._mar._avr._mai._juin._juil._août._sept._oct._nov._déc.'.split('_');

const DateAndTime = () => {
  const [time, setTime] = useState(new Date().toLocaleTimeString());
  const today = new Date();

  useEffect(() => {
    const refreshTime = setInterval(
      () =>
        setTime(
          new Date().toLocaleTimeString([], {
            timeStyle: 'short'
          })
        ),
      1000
    );

    return () => {
      clearInterval(refreshTime);
    };
  }, []);

  const day = days[today.getDay()];
  const date = today.getDate();
  const month = months[today.getMonth()];
  const longDate = `${date} ${month}`;

  return <span>{longDate}</span>;
};

export default DateAndTime;
