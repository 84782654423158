/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { fetchTripsList } from './round.actions';

const initialState = {
  // data is for trips with status 'picking'
  data: [],
  selectedRound: {},
  status: 'idle'
};

const roundSlice = createSlice({
  name: 'round',
  initialState,
  reducers: {
    setRound: (state, { payload }) => {
      state.selectedRound = payload;
    },
    resetRounds: (state) => {
      state.data = [];
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTripsList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTripsList.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        console.table(payload);
        state.data = payload;
      })
      .addCase(fetchTripsList.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export const { setRound, resetRounds } = roundSlice.actions;

export default roundSlice.reducer;
