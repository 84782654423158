/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';
import { BackButton, Loader } from '@/components';
import { fetchTripsList, switchTripStatus } from '@/redux/round/round.actions';
import { resetRounds } from '@/redux/round/round.slice';
import TripDetailsModal from '../TripDetailsModal/TripDetailsModal';
import { toggleModal } from '@/redux/modal/modal.slice';

const ActivateTripPage = () => {
  const { t } = useTranslation();

  const [selectedTrip, setSelectedTrip] = useState({});
  const dispatch = useDispatch();
  const { data, status: reqStatus } = useSelector((state) => state.round);

  useEffect(() => {
    try {
      dispatch(fetchTripsList()).unwrap();
    } catch (e) {
      console.log(e);
    }

    return () => dispatch(resetRounds());
  }, []);

  const handleTripClick = async (payload) => {
    await dispatch(switchTripStatus(payload));
    dispatch(fetchTripsList());
  };

  const getTripDetails = (trip) => {
    setSelectedTrip(trip);
    dispatch(toggleModal(true));
  };
  const dataToSort = [...data];

  const sortedData = dataToSort?.sort((tripA, tripB) =>
    tripB.status === 'picking' || tripB.status === 'packaged'
      ? tripB.pickingStatus === 'finished' || tripB.pickingStatus === 'started'
        ? 1
        : 0
      : -1
  );
  const renderedTrips = sortedData?.map((trip) => {
    const { pickingStatus, status } = trip;

    return (
      <>
        <div>
          <div className="p-activate-trip__trip">
            <div
              className="p-activate-trip__trip-name"
              onClick={() => getTripDetails(trip)}
            >
              {trip.name}{' '}
              <span className="p-activate-trip__trip-info">
                <InfoIcon />
              </span>
            </div>
            {status === 'created' ? (
              <button
                className="p-activate-trip__btn p-activate-trip__btn--activate"
                type="button"
                onClick={() =>
                  handleTripClick({ tripID: trip.id, status: 'picking' })
                }
              >
                {t('views.activate_trip_page.activate_button')}
              </button>
            ) : pickingStatus === 'created' ? (
              <button
                className="p-activate-trip__btn p-activate-trip__btn--deactivate"
                type="button"
                onClick={() =>
                  handleTripClick({ tripID: trip.id, status: 'created' })
                }
              >
                {t('views.activate_trip_page.deactivate_button')}
              </button>
            ) : (
              <small>{t('views.activate_trip_page.active')}</small>
            )}
          </div>

          <div>
            {trip.totalPackages} paniers{' '}
            {pickingStatus === 'created' ? (
              status === 'picking' ? (
                <span className={`p-activate-trip__status ${pickingStatus}`}>
                  - {t('views.activate_trip_page.status_created')}
                </span>
              ) : (
                t('views.activate_trip_page.status_to_create')
              )
            ) : pickingStatus === 'started' ? (
              <span className={`p-activate-trip__status ${pickingStatus}`}>
                - 🔍 {t('views.activate_trip_page.status_started')}
              </span>
            ) : (
              <span className={`p-activate-trip__status ${pickingStatus}`}>
                - ✅ {t('views.activate_trip_page.status_over')}
              </span>
            )}
          </div>
        </div>
        <hr />
      </>
    );
  });
  return (
    <div className="p-activate-trip">
      <BackButton />
      <div className="p-activate-trip__container">
        {reqStatus === 'loading' ? (
          <Loader />
        ) : data ? (
          <>
            <div className="p-activate-trip__title">
              {t('views.activate_trip_page.title')}
            </div>
            <div className="p-activate-trip__trips">{renderedTrips}</div>
          </>
        ) : (
          <p className="p-activate-trip__not-found">
            {t('views.activate_trip_page.not_found')} ❌
          </p>
        )}
      </div>

      <TripDetailsModal
        trip={selectedTrip}
        handleModalClose={() => setSelectedTrip({})}
      />
    </div>
  );
};

export default ActivateTripPage;
