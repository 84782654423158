import { createSlice } from '@reduxjs/toolkit';
import {
  getBatchs,
  getBatch,
  updateMoveLine,
  closeBatch,
  closeSession
} from '@/redux/restock/restock.actions';

const initialState = {
  batchs: {},
  currentBatch: {},
  restockStep: 'scan_product',
  moveLine: {}
};

const restockSlice = createSlice({
  name: 'restock',
  initialState,
  reducers: {
    resetRestock: (state) => {
      state.batchs = {};
      state.currentBatch = {};
      state.moveLine = {};
      state.restockStep = 'scan_product';
    },
    updateRestockStep: (state, action) => {
      state.restockStep = action.payload.restockStep;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getBatchs.fulfilled, (state, { payload }) => {
        state.batchs.data = payload;
        state.batchs.status = 'succeeded';
      })
      .addCase(getBatchs.pending, (state) => {
        state.batchs.status = 'loading';
      })
      .addCase(getBatchs.rejected, (state) => {
        state.batchs.status = 'failed';
      })
      .addCase(getBatch.fulfilled, (state, { payload }) => {
        state.currentBatch.data = payload;
        state.currentBatch.status = 'succeeded';
      })
      .addCase(getBatch.pending, (state) => {
        state.currentBatch.status = 'loading';
      })
      .addCase(getBatch.rejected, (state) => {
        state.currentBatch.status = 'failed';
      })
      .addCase(updateMoveLine.rejected, (state) => {
        state.moveLine.status = 'failed';
      })
      .addCase(updateMoveLine.pending, (state) => {
        state.moveLine.status = 'loading';
      })
      .addCase(updateMoveLine.fulfilled, (state, { payload }) => {
        state.moveLine.data = payload;
        state.moveLine.status = 'succeeded';
      })
      .addCase(closeBatch.fulfilled, (state) => {
        state.currentBatch.status = 'closed';
      })
      .addCase(closeSession.fulfilled, (state) => {
        state.currentBatch.status = 'paused';
      });
  }
});

export const { resetRestock, updateRestockStep } = restockSlice.actions;

export default restockSlice.reducer;
