import React from 'react';
import { Link } from 'react-router-dom';

const features = [
  {
    title: 'Gestion des tournées',
    link: '/admin/active-trip'
  },
  {
    title: 'Gestion des étagères mobiles',
    link: '/admin/reset-mob'
  }
];
const AdminPage = () => {
  const renderedFeatures = features.map((f) => (
    <li className="p-admin__feature">
      <Link to={f.link}>{f.title}</Link>
    </li>
  ));
  return <ul className="p-admin">{renderedFeatures}</ul>;
};

export default AdminPage;
