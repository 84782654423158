import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { SelectOption } from '@/components';
import { setMode } from '@/redux/mode/mode.slice';
import { selectUser } from '@/redux/user/user.slice';
import { getBatchs } from '@/redux/restock/restock.actions';

const SelectMode = () => {
  const { t } = useTranslation();

  const { data } = useSelector((state) => state.mode);
  const { country } = useSelector(selectUser);
  const { id: userID } = useSelector(selectUser);

  const dispatch = useDispatch();

  useEffect(() => {
    if (country === 'es') i18n.changeLanguage('es-ES');
    dispatch(setMode({}));
  }, []);

  const handleModeSelection = (mode) => {
    dispatch(setMode(mode));
  };

  useEffect(() => {
    if (userID) dispatch(getBatchs(userID));
  }, [userID]);

  return (
    <div className="v-select-mode u-page-center">
      <div className="v-select-mode__title">{t('views.select_mode.title')}</div>
      <div className="c-select-mode__content">
        {data.map(
          (option) =>
            option.isVisible && (
              <SelectOption
                option={option}
                link={option.id}
                name={t(`views.select_mode.${option.name}`)}
                onSelect={handleModeSelection}
              />
            )
        )}
      </div>
    </div>
  );
};

export default SelectMode;
