/* eslint-disable import/prefer-default-export */
/* eslint-disable no-undef */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { axios, isProd } from '@/helpers/api';
import { bookMobileRack } from '../supplyProducts/supplyProducts.actions';
import { setMobileRack } from '../supplyProducts/supplyProducts.slice';
import {
  getGroceriesByPackage,
  validateAndTransferProduct,
  validateAndTransferProductsList
} from '../fillProducts/fillProducts.actions';
import { LITTLE_HUB_KEY } from '@/constants/hubs';

import { getBasket } from '../baskets/baskets.actions';

import { updateMoveLine } from '@/redux/restock/restock.actions';
import { resetBasket } from '@/redux/baskets/baskets.slice';
import { updateRestockStep } from '@/redux/restock/restock.slice';
import {
  updateWarehouseFlowStep,
  updateStoredBarcode,
  updateDestBarcode,
  updateProductQty,
  updateDosingPcbData,
  updateProductName,
  resetWarehouseFlows
} from '@/redux/warehouseFlows/warehouseFlows.slice';
import {
  getPalletId,
  getPcbIds,
  getProductName,
  getProductPackaging,
  getStockQuant,
  getStockQuantPackageByID,
  getQuantIds,
  getQuantPackageInfos,
  getAliasFromMob,
  sendAliasesToEngine
} from '../warehouseFlows/warehouseFlows.actions';

const regexNumbersOnly = /^\d+$/;
const MOBILE_RACK = 'MOB';
const MISSING_PRODUCT = '0000000000000';
const BASKET_SCAN = '-';
const validPalletInput = /\bPAL\d*\b/;
const validDosingInput = /^PAR-DOS-\d{3}$/;
const validMobInput = /^PAR-MOB-\d{3}-\d{2}$/;
const validProdInput = /^PAR-PROD-[AB]$/;
const validStockInput = /^PAR-(?:FL|FRIG)-\d{3}$/;

function isValidWarehouseFlowInput(input) {
  if (
    validPalletInput.test(input) ||
    validDosingInput.test(input) ||
    validMobInput.test(input) ||
    validProdInput.test(input) ||
    validStockInput.test(input)
  ) {
    return true;
  }
  return false;
}

export const validateScan = createAsyncThunk(
  'barcode/validateScan',
  async (
    { type, value, expectedValue, restockStep },
    { getState, dispatch }
  ) => {
    const { hubId, hubKind } = getState().user.data;
    const { currentBatch } = getState().restock;
    const isLittleHub = hubKind === LITTLE_HUB_KEY;

    switch (type) {
      case 'filledBasket': {
        if (value === 'Print_error') {
          dispatch(resetBasket());
          return {
            value,
            isScanValid: false,
            warning: true,
            type,
            message: 'views.print_label.error_no_printer_found'
          };
        }
        const data = await dispatch(getBasket({ value }));

        if (!data.payload) {
          return {
            value,
            isScanValid: false,
            warning: true,
            type
          };
        }

        return {
          value,
          isScanValid: true,
          warning: false,
          type
        };
      }

      case 'rack': {
        const isRack = value.includes(MOBILE_RACK);
        if (!isRack) {
          // This is not even a rack
          return {
            value,
            isScanValid: false,
            warning: false,
            type
          };
        }
        const res = await dispatch(bookMobileRack(value));
        const isRejected = res.meta.requestStatus === 'rejected';
        if (!isRejected || isLittleHub) {
          dispatch(setMobileRack(value));
        }
        if (res.payload === 406 && !isLittleHub) {
          const { data } = await axios.get(
            `/picking/hub/${hubId}/provision/status`
          );

          const selectedRoundName = getState().round.selectedRound.name;

          const isSameRound = selectedRoundName === data?.[value]?.trip;
          if (isSameRound) {
            dispatch(setMobileRack(value));
          }
          // => means that the rack is already allocated => display modal
          return {
            value,
            isScanValid: isSameRound,
            warning: !isSameRound,
            type
          };
        }

        return {
          value,
          isScanValid: !isRejected || isLittleHub,
          warning: false,
          type
        };

        // Rack
      }

      case 'product': {
        const isProduct = value.match(regexNumbersOnly);
        if (!isProduct) {
          // This is not even a product
          return {
            value,
            isScanValid: false,
            warning: "Ceci n'est pas un produit",
            type
          };
        }
        const selectedMode = getState().mode.selectedMode.id;

        if (selectedMode === 'fill') {
          const productsToFill = getState().fillProducts.data.products;
          const scannedProduct = productsToFill?.find((p) => p.EAN13 === value);

          if (scannedProduct) {
            let res;
            if (hubId === 1) {
              res = await dispatch(
                validateAndTransferProductsList(productsToFill)
              );
            } else {
              res = await dispatch(validateAndTransferProduct(value));
            }

            if (res.payload?.status === 500) {
              return {
                value,
                isScanValid: false,
                warning: 'Produit déjà scanné',
                type
              };
            }
            return {
              value,
              isScanValid: true,
              warning: false,
              type
            };
          }
          return {
            value,
            isScanValid: false,
            warning: 'Produit inconnu dans cette commande',
            type
          };
        }
        if (value === MISSING_PRODUCT) {
          return {
            value,
            isScanValid: true,
            warning: true,
            type
          };
        }
        // This is product, but could be the wrong one
        const productToScanEAN13 =
          getState().supplyProducts.productToScan.EAN13;

        return {
          value,
          isScanValid: productToScanEAN13 === value,
          warning: false,
          type
        };
      }

      case 'basket': {
        // scanning basket

        const isBasket = value.includes(BASKET_SCAN);
        if (isBasket) {
          const res = await dispatch(getGroceriesByPackage(value));
          if (res?.payload?.status === 406) {
            const reason = res.payload.reason.includes(
              'but no provision has started'
            )
              ? 'Approvisionnement pas encore commencé pour ce panier'
              : 'Approvisionnement pas encore prêt pour ce panier';
            return {
              value,
              isScanValid: true,
              warning: reason,
              type
            };
          }
          if (res?.payload?.status === 500) {
            const reason = res.payload.reason.includes(
              'resource does not exist'
            )
              ? 'Panier inconnu'
              : res.payload.reason;
            return {
              value,
              isScanValid: false,
              warning: reason,
              type
            };
          }
          return {
            value,
            isScanValid: true,
            warning: false,
            type
          };
        }
        return {
          value,
          isScanValid: false,
          // TODO: check if value.includes(BASKET_SCAN),
          warning: false,
          type
        };
      }

      case 'restock_product': {
        let showError = false;
        let textError = '';
        const user = JSON.parse(localStorage.getItem('user'));

        if (restockStep === 'scan_product') {
          if (value === expectedValue) {
            dispatch(updateRestockStep({ restockStep: 'quantities_input' }));
          } else {
            showError = true;
            textError = 'views.restock.wrong_product';
          }
        }

        if (restockStep === 'list_products_magzone') {
          const barcodes = expectedValue.map(
            (barcode) => barcode.product_barcode
          );
          if (barcodes.includes(value)) {
            const productStored = expectedValue.filter(
              (product) => product.product_barcode === value
            );
            const productsMag = expectedValue.filter(
              (product) => product.product_barcode !== value
            );
            localStorage.setItem(
              `productsMag_${user.id}`,
              JSON.stringify(productsMag)
            );
            localStorage.setItem(
              `productsStored_${user.id}`,
              JSON.stringify(productStored[0])
            );
            dispatch(updateRestockStep({ restockStep: 'scan_mag' }));
          } else {
            showError = true;
            textError = 'views.restock.product_already_scanned';
          }
        }

        if (restockStep === 'scan_mag') {
          if (value === expectedValue) {
            const productsMag = JSON.parse(
              localStorage.getItem(`productsMag_${user.id}`)
            );
            const productToStore = JSON.parse(
              localStorage.getItem(`productsStored_${user.id}`)
            );

            const res = await dispatch(
              updateMoveLine({
                moveLineId: productToStore.move_line_id,
                batchId: currentBatch?.data?.batch_id,
                employeeId: user.id,
                productId: productToStore.product_id,
                qtyDone: productToStore.qty_done
              })
            );

            if (res.payload.http_status !== 200) {
              dispatch(
                updateRestockStep({ restockStep: 'list_products_magzone' })
              );
              return {
                value,
                isScanValid: false,
                warning: true,
                message: res.payload,
                type
              };
            }

            if (productsMag?.length === 0) {
              dispatch(updateRestockStep({ restockStep: 'job_completed' }));
            } else {
              dispatch(
                updateRestockStep({ restockStep: 'list_products_magzone' })
              );
            }
          } else {
            showError = true;
            textError = 'views.restock.wrong_location';
          }
        }

        if (restockStep === 'close_batch') {
          showError = true;
          textError = 'views.restock.cant_close_batch';
        }

        if (showError) {
          return {
            value,
            isScanValid: false,
            warning: true,
            message: textError,
            type
          };
        }
        return null;
      }

      case 'warehouse-flows': {
        const { storedBarcode, warehouseFlowStep } = getState().warehouseFlows;
        let textError;
        if (
          (storedBarcode.match(validStockInput) &&
            value.match(validStockInput)) ||
          (storedBarcode.match(validMobInput) &&
            value.match(validPalletInput)) ||
          (storedBarcode.match(validMobInput) &&
            value.match(validDosingInput)) ||
          (storedBarcode.match(validProdInput) && value.match(validMobInput)) ||
          (storedBarcode.match(validStockInput) &&
            value.match(validMobInput)) ||
          (storedBarcode.match(validDosingInput) &&
            value.match(validStockInput))
        ) {
          textError = 'views.warehouse_flows.not_a_valid_transfer_scan';

          return {
            value,
            isScanValid: false,
            warning: true,
            message: textError,
            type
          };
        }

        if (!isValidWarehouseFlowInput(value)) {
          textError = 'views.warehouse_flows.not_a_valid_barcode';
          dispatch(resetWarehouseFlows());
          return {
            value,
            isScanValid: false,
            warning: true,
            message: textError,
            type
          };
        }

        if (value.match(validMobInput)) {
          let preventer = false;
          if (storedBarcode.match(validPalletInput)) {
            dispatch(
              updateWarehouseFlowStep({ warehouseFlowStep: 'select-pcb' })
            );
            dispatch(updateDestBarcode({ destBarcode: value }));
            preventer = true;
          }
          if (!preventer) {
            dispatch(updateStoredBarcode({ storedBarcode: value }));
            const quantData = await dispatch(getQuantIds(value));

            if (quantData.error) {
              textError = 'views.warehouse_flows.horreo_error_get_quant_ids';
              dispatch(resetWarehouseFlows());
              return {
                value,
                isScanValid: false,
                warning: true,
                message: textError,
                secondaryMessage: quantData.payload,
                type
              };
            }

            const stockQuant = await dispatch(
              getStockQuant(quantData?.payload[0]?.quant_ids[0])
            );

            if (stockQuant.error) {
              textError = 'views.warehouse_flows.horreo_error_get_stock_quant';
              dispatch(resetWarehouseFlows());

              return {
                value,
                isScanValid: false,
                warning: true,
                message: textError,
                secondaryMessage: stockQuant.payload,
                type
              };
            }

            const pcbId = stockQuant?.payload?.product_packaging_id;

            const productId = stockQuant?.payload?.product_id;

            const productName = await dispatch(getProductName(productId));

            if (productName.error) {
              textError = 'views.warehouse_flows.horreo_error_get_product_name';
              dispatch(resetWarehouseFlows());

              return {
                value,
                isScanValid: false,
                warning: true,
                message: textError,
                secondaryMessage: productName.payload,
                type
              };
            }

            const packagingData = await dispatch(getProductPackaging(pcbId));

            if (packagingData.error) {
              textError =
                'views.warehouse_flows.horreo_error_get_product_packaging';
              dispatch(resetWarehouseFlows());

              return {
                value,
                isScanValid: false,
                warning: true,
                message: textError,
                secondaryMessage: packagingData.payload,
                type
              };
            }

            dispatch(
              updateProductName({ productName: productName.payload.name })
            );

            dispatch(
              updateDosingPcbData({
                dosingPcbData: {
                  name: packagingData.payload.display_name,
                  id: pcbId
                }
              })
            );

            dispatch(
              updateWarehouseFlowStep({ warehouseFlowStep: 'mob-scanned' })
            );
          }
        }

        if (value.match(validProdInput)) {
          if (!storedBarcode) {
            textError = 'views.warehouse_flows.not_a_valid_transfer_scan';

            return {
              value,
              isScanValid: false,
              warning: true,
              message: textError,
              type
            };
          }

          if (storedBarcode.match(validMobInput)) {
            const user = JSON.parse(localStorage.getItem('user'));

            const aliasesQuery = await dispatch(
              getAliasFromMob({ barcode: storedBarcode, employeeId: user.id })
            );
            const lastDashIndex = value.lastIndexOf('-');
            const extractedValue = value.substring(lastDashIndex + 1);

            await dispatch(
              sendAliasesToEngine({
                lineId: extractedValue,
                productIds: aliasesQuery?.payload?.result[0].aliases,
                availability: 'soonAvailable'
              })
            );

            dispatch(
              updateWarehouseFlowStep({
                warehouseFlowStep: 'mob_to_prod_to_validate'
              })
            );
            dispatch(updateDestBarcode({ destBarcode: value }));
          }
        }

        if (value.match(validPalletInput)) {
          let callPreventer = false;

          if (
            storedBarcode.match(validPalletInput) &&
            value !== storedBarcode
          ) {
            dispatch(updateDestBarcode({ destBarcode: value }));

            const quantPackageInfos = await dispatch(
              getQuantPackageInfos(value)
            );

            if (!quantPackageInfos.payload[0]?.content_packaging_id[0]) {
              dispatch(
                updateWarehouseFlowStep({
                  warehouseFlowStep: 'select-pcb-pallet'
                })
              );
            } else {
              dispatch(
                updateDosingPcbData({
                  dosingPcbData: {
                    name: quantPackageInfos.payload[0]?.content_packaging_id[1],
                    id: quantPackageInfos.payload[0]?.content_packaging_id[0]
                  }
                })
              );

              dispatch(
                updateWarehouseFlowStep({
                  warehouseFlowStep: 'pcb-selected-pallet'
                })
              );
            }

            callPreventer = true;
          }
          if (
            warehouseFlowStep !== 'pallet_to_pallet_to_validate' &&
            !callPreventer
          ) {
            const palletId = await dispatch(getPalletId(value));

            if (palletId.error) {
              textError = 'views.warehouse_flows.horreo_error_get_pallet_id';
              dispatch(resetWarehouseFlows());

              return {
                value,
                isScanValid: false,
                warning: true,
                message: textError,
                secondaryMessage: palletId.payload,
                type
              };
            }

            const palletPayload = palletId?.payload;

            const quantIdsData = await dispatch(
              getStockQuantPackageByID(palletPayload)
            );

            if (quantIdsData.error) {
              textError =
                'views.warehouse_flows.horreo_error_get_stockquant_package_by_id';
              dispatch(resetWarehouseFlows());

              return {
                value,
                isScanValid: false,
                warning: true,
                message: textError,
                secondaryMessage: quantIdsData.payload,
                type
              };
            }

            const quantIds = quantIdsData?.payload?.quant_ids;

            const productIdData = await dispatch(getStockQuant(quantIds));

            if (productIdData.error) {
              textError = 'views.warehouse_flows.horreo_error_get_stock_quant';
              dispatch(resetWarehouseFlows());

              return {
                value,
                isScanValid: false,
                warning: true,
                message: textError,
                secondaryMessage: productIdData.payload,
                type
              };
            }

            const productId = productIdData?.payload?.product_id;

            const productName = await dispatch(getProductName(productId));

            if (productName.error) {
              textError = 'views.warehouse_flows.horreo_error_get_product_name';
              dispatch(resetWarehouseFlows());

              return {
                value,
                isScanValid: false,
                warning: true,
                message: textError,
                secondaryMessage: productName.payload,
                type
              };
            }

            const pcbIdsData = await dispatch(getPcbIds(productId));

            if (pcbIdsData.error) {
              textError = 'views.warehouse_flows.horreo_error_get_pcb_ids';
              dispatch(resetWarehouseFlows());

              return {
                value,
                isScanValid: false,
                warning: true,
                message: textError,
                secondaryMessage: pcbIdsData.payload,
                type
              };
            }

            const pcbIds = pcbIdsData?.payload;

            const packagingIds = await Promise.all(
              pcbIds.map(async (id) => {
                const packagingId = await dispatch(getProductPackaging(id));
                return {
                  name: packagingId.payload.name,
                  id
                };
              })
            );

            if (packagingIds.error) {
              textError =
                'views.warehouse_flows.horreo_error_get_product_packaging';
              dispatch(resetWarehouseFlows());

              return {
                value,
                isScanValid: false,
                warning: true,
                message: textError,
                secondaryMessage: packagingIds.payload,
                type
              };
            }

            dispatch(updateProductQty({ qty: productIdData.payload.quantity }));
            dispatch(
              updateProductName({ productName: productName.payload.name })
            );
            dispatch(
              updateStoredBarcode({ storedBarcode: quantIdsData.payload.name })
            );
            dispatch(updateDosingPcbData({ dosingPcbData: packagingIds }));
            dispatch(updateStoredBarcode({ storedBarcode: value }));
            dispatch(
              updateWarehouseFlowStep({ warehouseFlowStep: 'pallet-scanned' })
            );
          }
        }

        if (value.match(validDosingInput)) {
          if (!storedBarcode) {
            textError = 'views.warehouse_flows.not_a_valid_transfer_scan';

            return {
              value,
              isScanValid: false,
              warning: true,
              message: textError,
              type
            };
          }

          if (storedBarcode.match(validPalletInput)) {
            dispatch(updateDestBarcode({ destBarcode: value }));
            dispatch(
              updateWarehouseFlowStep({
                warehouseFlowStep: 'pallet_to_dosing_to_validate'
              })
            );
          }
        }

        if (value.match(validStockInput)) {
          if (!storedBarcode) {
            textError = 'views.warehouse_flows.not_a_valid_transfer_scan';

            return {
              value,
              isScanValid: false,
              warning: true,
              message: textError,
              type
            };
          }

          if (storedBarcode.match(validPalletInput)) {
            dispatch(
              updateWarehouseFlowStep({
                warehouseFlowStep: 'pallet_to_stock_to_validate'
              })
            );
            dispatch(updateDestBarcode({ destBarcode: value }));
          }
        }

        return null;
      }

      default:
        return true;
    }
  }
);
