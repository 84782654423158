import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ScanContainer, FlashScreen } from '@/components';
import { getGroceriesByPackage } from '@/redux/fillProducts/fillProducts.actions';
import { resetIsScanValid } from '@/redux/barcode/barcode.slice';
import { isEmpty } from '@/helpers/helpers';
import { selectUser } from '@/redux/user/user.slice';

const FillScanProduct = () => {
  const { t } = useTranslation();

  const { country } = useSelector(selectUser);
  const { isScanValid, warning, type } = useSelector((state) => state.barcode);
  const { data } = useSelector((state) => state.fillProducts);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  console.table(data.products);

  console.log({ isScanValid, type });

  useEffect(() => {
    if (isEmpty(data)) {
      navigate('/fill');
    }
  }, []);

  useEffect(() => {
    if (isScanValid && type === 'product') {
      console.log('refetch groceries to fill');
      dispatch(getGroceriesByPackage(data.package));
      dispatch(resetIsScanValid());
    }
  }, [isScanValid, type]);

  const qtyToScan = data.products?.reduce(
    (prev, curr) => prev + curr.quantity,
    0
  );

  if (qtyToScan === 0) {
    return <Navigate replace to="/fill" />;
  }

  const alreadyScanned = [];
  const toScan = [];

  data.products?.map((p) =>
    p.alreadyPicked ? alreadyScanned.push(p) : toScan.push(p)
  );
  const missingQty = data.products?.reduce(
    (prev, curr) => prev + curr.missingQuantity,
    0
  );
  console.log({ missingQty });

  const view = (
    <>
      <div className="v-fill-scan">
        <div className="v-fill-scan__place">
          <div>{t('views.fill_scan_product.scan_place_text')}</div>
          <span className="v-fill-scan__place-number">{data.placeName}</span>
        </div>
        <br />

        {alreadyScanned.length > 0 && (
          <>
            <div className="v-fill-scan__scanned-container">
              {t('views.fill_scan_product.already_scanned')} ✅{' '}
            </div>
            <ul>
              {alreadyScanned.map((p) => (
                <li className="v-fill-scan__scanned-product">{p.name}</li>
              ))}
            </ul>
          </>
        )}
        <br />
        <div className="v-fill-scan__qty-to-scan">
          <span>
            {qtyToScan}
            {t('views.fill_scan_product.qty_to_scan_1')}
            {!country && qtyToScan > 1 ? 's' : ''}{' '}
          </span>{' '}
          {t('views.fill_scan_product.qty_to_scan_2')} 🔎{' '}
        </div>
        <ul>
          {toScan.map((p) => (
            <li className=" v-fill-scan__to-scan">
              {p.quantity} x {p.name}
            </li>
          ))}{' '}
        </ul>
        <br />
        {missingQty > 0 && (
          <div className="v-fill-scan__note">
            {t('views.fill_scan_product.scan_note')} 📄.{' '}
          </div>
        )}
      </div>

      <div className="u-scan">{t('views.fill_scan_product.scan')}</div>
    </>
  );

  if (warning) {
    return <FlashScreen type="error" message={warning} />;
  }

  // if placeName null and products array is empty => no grocery for this basket

  return <ScanContainer type="product">{view}</ScanContainer>;
};

export default FillScanProduct;
