import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosHorreo, openApiHorreo, engine } from '@/helpers/api';

export const getPalletId = createAsyncThunk(
  'warehouseFlows/getPalletId',
  async (palletBarcode, { __, ___, rejectWithValue }) => {
    try {
      const { data } = await openApiHorreo.patch(
        `/stock.quant.package/call/search`,
        {
          args: [[['name', '=', `${palletBarcode}`]]]
        }
      );

      if (data) {
        return data;
      }

      return rejectWithValue('Pallette ID introuvable');
    } catch (e) {
      console.log({ e });
      return rejectWithValue('Pallette ID introuvable');
    }
  }
);

export const getQuantPackageInfos = createAsyncThunk(
  'warehouseFlows/getQuantPackageInfos',
  async (palletBarcode, { __, ___, rejectWithValue }) => {
    try {
      const { data } = await openApiHorreo.patch(
        `/stock.quant.package/call/search_read`,
        {
          args: [[['name', '=', `${palletBarcode}`]]]
        }
      );

      if (data) {
        return data;
      }

      return rejectWithValue('Pallette ID introuvable');
    } catch (e) {
      console.log({ e });
      return rejectWithValue('Pallette ID introuvable');
    }
  }
);

export const getStockQuantPackageByID = createAsyncThunk(
  'warehouseFlows/getStockQuantPackageByID',
  async (palletId, { __, ___, rejectWithValue }) => {
    try {
      const { data } = await openApiHorreo.get(
        `/stock.quant.package/${palletId}`
      );

      if (data) {
        console.log(data);
        return data;
      }

      return rejectWithValue('Le package est introuvable');
    } catch (e) {
      console.log({ e });
      return rejectWithValue('Le package est introuvable');
    }
  }
);

export const getStockQuant = createAsyncThunk(
  'warehouseFlows/getStockQuant',
  async (productId, { __, ___, rejectWithValue }) => {
    try {
      const { data } = await openApiHorreo.get(`/stock.quant/${productId}`);

      if (data) {
        return data;
      }

      return rejectWithValue('Le stock quant est introuvable');
    } catch (e) {
      console.log({ e });
      return rejectWithValue('Le stock quant est introuvable');
    }
  }
);

export const getPcbIds = createAsyncThunk(
  'warehouseFlows/getPcbIds',
  async (productId, { __, ___, rejectWithValue }) => {
    try {
      const { data } = await openApiHorreo.patch(
        `/product.packaging/call/search`,
        {
          args: [[['product_id', '=', productId]]]
        }
      );

      if (data) {
        return data;
      }

      return rejectWithValue(
        'Les IDs des différents types de PCB sont introuvables'
      );
    } catch (e) {
      console.log({ e });
      return rejectWithValue(
        'Les IDs des différents types de PCB sont introuvables'
      );
    }
  }
);

export const getProductName = createAsyncThunk(
  'warehouseFlows/getProductName',
  async (productId, { __, ___, rejectWithValue }) => {
    try {
      const { data } = await openApiHorreo.get(`/product.product/${productId}`);

      if (data) {
        return data;
      }

      return rejectWithValue('Le nom du produit est introuvable');
    } catch (e) {
      console.log({ e });
      return rejectWithValue('Le nom du produit est introuvable');
    }
  }
);

export const getProductPackaging = createAsyncThunk(
  'warehouseFlows/getProductPackaging',
  async (pcbId, { __, ___, rejectWithValue }) => {
    try {
      const { data } = await openApiHorreo.get(`/product.packaging/${pcbId}`);

      if (data) {
        return data;
      }

      return rejectWithValue(
        `Le packaging avec l'id '${pcbId}' est introuvable`
      );
    } catch (e) {
      console.log({ e });
      return rejectWithValue(
        `Le packaging avec l'id '${pcbId}' est introuvable`
      );
    }
  }
);

export const movePallet = createAsyncThunk(
  'warehouseFlows/movePallet',
  async (
    { employeeId, palletBarcode, destBarcode },
    { __, ___, rejectWithValue }
  ) => {
    try {
      const { data } = await axiosHorreo.post('/api/stock/move/move_package', {
        employee_id: employeeId,
        package_name: palletBarcode,
        dest_barcode: destBarcode
      });
      if (data) {
        return data;
      }

      return rejectWithValue("Impossible d'effectuer le mouvement");
    } catch (e) {
      console.log({ e });
      return rejectWithValue("Impossible d'effectuer le mouvement");
    }
  }
);

export const supplyProdline = createAsyncThunk(
  'warehouseFlows/supplyProdline',
  async (
    { employeeId, sourceBarcode, destBarcode, qty },
    { __, ___, rejectWithValue }
  ) => {
    try {
      const { data } = await axiosHorreo.post(
        '/api/stock/move/move_in_production',
        {
          employee_id: employeeId,
          source_barcode: sourceBarcode,
          dest_barcode: destBarcode,
          quantity: qty
        }
      );
      const { result } = data;
      if (result.http_status === 200) {
        return result;
      }

      return rejectWithValue(
        'Déplacement sur la ligne de production impossible'
      );
    } catch (e) {
      console.log({ e });
      return rejectWithValue(
        'Déplacement sur la ligne de production impossible'
      );
    }
  }
);

export const getAliasFromMob = createAsyncThunk(
  'warehouseFlows/getAliasFromMob',
  async ({ employeeId, barcode }, { __, ___, rejectWithValue }) => {
    try {
      const { data } = await axiosHorreo.post('/api/alias_in_mob', {
        employee_id: employeeId,
        barcode,
        username: process.env.REACT_APP_HORREO_USER_NAME,
        token: process.env.REACT_APP_HORREO_TOKEN
      });
      const { result } = data;
      if (result.http_status === 200) {
        return result;
      }

      return rejectWithValue('Impossible de récupérer les shopifyIDs');
    } catch (e) {
      console.log({ e });
      return rejectWithValue('Impossible de récupérer les shopifyIDs');
    }
  }
);

export const movePackaging = createAsyncThunk(
  'warehouseFlows/movePackaging',
  async (
    { employeeId, destBarcode, pcbQty, sourcePackage, packagingId },
    { __, ___, rejectWithValue }
  ) => {
    try {
      const { data } = await axiosHorreo.post(
        '/api/stock/move/move_packaging_from_location',
        {
          employee_id: employeeId,
          source_package: sourcePackage,
          dest_barcode: destBarcode,
          quantity: pcbQty,
          packaging_id: packagingId
        }
      );

      const { result } = data;

      // Api doesn't return http_status 200 here..
      if (result.http_status === 200) {
        return result;
      }

      return rejectWithValue(
        'Déplacement sur la ligne de production impossible'
      );
    } catch (e) {
      console.log({ e });
      return rejectWithValue(
        'Déplacement sur la ligne de production impossible'
      );
    }
  }
);

export const getQuantIds = createAsyncThunk(
  'warehouseFlows/getQuantIds',
  async (barcode, { __, ___, rejectWithValue }) => {
    try {
      const { data } = await openApiHorreo.patch(
        `/stock.location/call/search_read`,
        {
          args: [[['barcode', '=', `${barcode}`]]]
        }
      );

      if (data) {
        return data;
      }

      return rejectWithValue(
        'Les IDs des différents types de PCB sont introuvables'
      );
    } catch (e) {
      console.log({ e });
      return rejectWithValue(
        'Les IDs des différents types de PCB sont introuvables'
      );
    }
  }
);

export const fillPackage = createAsyncThunk(
  'warehouseFlows/fillPackage',
  async (
    { employeeId, destBarcode, pcbQty, sourcePackage, packagingId },
    { __, ___, rejectWithValue }
  ) => {
    try {
      const { data } = await axiosHorreo.post('/api/stock/move/fill_package', {
        employee_id: employeeId,
        source_package: sourcePackage,
        package_name: destBarcode,
        quantity: pcbQty,
        packaging_id: packagingId
      });

      // console.log(pcbQty);
      const { result } = data;

      // Api doesn't return http_status 200 here..
      if (result.http_status === 200) {
        return result;
      }

      return rejectWithValue(
        'Déplacement sur la ligne de production impossible'
      );
    } catch (e) {
      console.log({ e });
      return rejectWithValue(
        'Déplacement sur la ligne de production impossible'
      );
    }
  }
);

export const sendAliasesToEngine = createAsyncThunk(
  'warehouseFlows/sendAliasesToEngine',
  async (
    { lineId, productIds, availability },
    { __, ___, rejectWithValue }
  ) => {
    try {
      const { status } = await fetch(
        `${engine}/api/v1/warehouse/alertUpcomingStock`,
        {
          method: 'POST',
          body: JSON.stringify({
            lineId,
            productIds,
            availability
          })
        }
      );
      if (status) {
        return status;
      }
      return rejectWithValue(
        "impossible d'envoyer la liste des shopify IDs à l'engine"
      );
    } catch (e) {
      console.log({ e });
      return rejectWithValue(
        "impossible d'envoyer la liste des shopify IDs à l'engine"
      );
    }
  }
);
