import React, { useState } from 'react';
import { Dropdown } from '@/components';

export const ENVIRONMENTS = [
  { label: 'Production', value: 'production' },
  { label: 'Staging', value: 'staging' }
];

function EnvironmentPage() {
  const [selectedEnv, setSelectedEnv] = useState(
    JSON.parse(localStorage.getItem('env') ?? '{}')
  );

  const handleSelect = (env) => {
    setSelectedEnv(env);
    localStorage.setItem(
      'env',
      JSON.stringify(ENVIRONMENTS.find((e) => e.value === env))
    );
    window.location.replace('/settings');
  };
  return (
    <div className="u-page-center">
      <div className="v-select-mode__title">
        Choisissez votre environment de travail
      </div>
      <Dropdown
        onSelect={handleSelect}
        selectedValue={selectedEnv?.value}
        options={ENVIRONMENTS}
      />
    </div>
  );
}

export default EnvironmentPage;
