import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const RackDetails = () => {
  const { t } = useTranslation();

  const { selectedRound } = useSelector((state) => state.round);
  const { mobileRack } = useSelector((state) => state.supplyProducts);
  const { totalToScan, totalScanned, missingProducts } = useSelector(
    (state) => state.supplyProducts
  );

  return (
    <div className="v-rack-details">
      <div className="v-rack-details__round">{selectedRound.name}</div>
      <div className="v-rack-details__rack">
        {t('views.rack_details.rack')}:{' '}
        <span className="v-rack-details__rack-name">{mobileRack}</span>
      </div>
      <div className="v-rack-details__total">
        {t('views.rack_details.total')}:{' '}
        <span className="v-rack-details__total-products">
          {totalScanned} / {totalToScan}
        </span>
        {missingProducts > 0 && (
          <span>
            {' '}
            <span className="v-rack-details__missing-products">
              {' '}
              ({missingProducts} {t('views.rack_details.missing_product')})
            </span>
          </span>
        )}
      </div>
    </div>
  );
};

export default RackDetails;
