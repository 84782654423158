export const LITTLE_HUB_KEY = 'little_hub';
export const BIG_HUB_KEY = 'big_hub';

export default [
  { id: 1, hub: 'Paris', hubKind: BIG_HUB_KEY, country: 'fr' },
  { id: 2, hub: 'Lyon', hubKind: BIG_HUB_KEY, country: 'fr' },
  { id: 3, hub: 'Marseille', hubKind: LITTLE_HUB_KEY, country: 'fr' },
  { id: 4, hub: 'Lille', hubKind: LITTLE_HUB_KEY, country: 'fr' },
  { id: 5, hub: 'Toulouse', hubKind: LITTLE_HUB_KEY, country: 'fr' },
  { id: 6, hub: 'Madrid', hubKind: LITTLE_HUB_KEY, country: 'es' },
  { id: 7, hub: 'Bordeaux', hubKind: LITTLE_HUB_KEY, country: 'fr' }
];
