import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { processValidProductScan } from '@/redux/supplyProducts/supplyProducts.slice';
import ScanProduct from '../ScanProduct/ScanProduct';
import { resetIsScanValid } from '@/redux/barcode/barcode.slice';
import {
  fetchSupplyProducts,
  processProductScan
} from '@/redux/supplyProducts/supplyProducts.actions';

const RACK_FIXED = 'MAG';

const ProductToScan = ({ product }) => {
  const { t } = useTranslation();

  const { imageURL, productName, sourceName, destinationNames, EAN13 } =
    product || {};

  const { isScanValid, warning, type } = useSelector((state) => state.barcode);
  const dispatch = useDispatch();

  console.log({ isScanValid, type });

  useEffect(() => {
    if (isScanValid && type === 'product') {
      console.log('processProductScan');
      dispatch(processProductScan(warning));

      setTimeout(() => {
        dispatch(fetchSupplyProducts());
        console.log('resetIsScanValid inside timeout');
        // dispatch(resetIsScanValid());
      }, 2000);
    }
  }, [isScanValid, type]);

  const locationStyle = clsx(
    'v-product-to-scan__location',
    `v-product-to-scan__location--${isScanValid ? 'scanned' : 'to-scan'}`
  );

  return (
    <div className="v-product-to-scan">
      <div className="v-product-to-scan__main">
        <img
          className="v-product-to-scan__image"
          src={imageURL}
          alt={productName}
        />
        <div className="v-product-to-scan__name">{productName}</div>
      </div>
      <div className="v-product-to-scan__scan">
        <ScanProduct EAN13={EAN13} />
      </div>
      {!isScanValid ? (
        <div className="v-product-to-scan__footer">
          {t('views.product_to_scan.scan_footer_not_valid')}{' '}
          <span className={locationStyle}>{sourceName}</span>{' '}
        </div>
      ) : (
        !warning && (
          <div className="v-product-to-scan__footer">
            {t('views.product_to_scan.scan_footer_valid')}{' '}
            <span className={locationStyle}>{destinationNames[0]}</span>{' '}
          </div>
        )
      )}
    </div>
  );
};

export default ProductToScan;
