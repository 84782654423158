import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import barcodeImg from '@/assets/barcode_scanner.png';
import downArrow from '@/assets/down_arrow.png';
import validCheck from '@/assets/valid_check.png';
import { validateScan } from '@/redux/barcode/barcode.actions';
import {
  movePackaging,
  movePallet,
  supplyProdline,
  fillPackage
} from '@/redux/warehouseFlows/warehouseFlows.actions';
import {
  updateWarehouseFlowStep,
  resetWarehouseFlows
} from '@/redux/warehouseFlows/warehouseFlows.slice';
import { DropdownAlt, FlashScreen, Loader, Modal } from '@/components';
import { toggleModal } from '@/redux/modal/modal.slice';

function WarehouseFlowsContent() {
  const {
    warehouseFlowStep,
    storedBarcode,
    destBarcode,
    dosingPcbData,
    productName,
    qty,
    isLoading
  } = useSelector((state) => state.warehouseFlows);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isScanValid, message, secondaryMessage } = useSelector(
    (state) => state.barcode
  );

  const [selectedPcb, setSelectedPcb] = useState(null);
  const [selectedQty, setSelectedQty] = useState(null);
  const [horreoError, setHorreoError] = useState({
    errorText: '',
    secondaryErrorText: '',
    hasError: false
  });

  const resetState = () => {
    setSelectedPcb(null);
    setSelectedQty(null);
  };

  const handleOptionSelect = (option) => {
    setSelectedQty(option);
  };

  const instructions = () => {
    if (
      warehouseFlowStep === 'pallet-scanned' ||
      warehouseFlowStep === 'mob-scanned'
    )
      return 'Scanner emplacement';
    return 'Scanner un élément';
  };

  const boldInstructions = () => {
    if (
      warehouseFlowStep === 'pallet-scanned' ||
      warehouseFlowStep === 'mob-scanned'
    )
      return 'destination';
    return 'à déplacer';
  };

  const instructionText = instructions() + boldInstructions();

  const stepTitle = () =>
    warehouseFlowStep === '' ? 'Flux à réaliser' : 'Transfert';

  const background = (type) => `${type}-bg`;

  const handleFakeScan = () => {
    const barcode = 'PAR-MOB-004-01';
    // const barcode = 'PAL004485';
    // const barcode = 'error';
    // const barcode = 'PAR-MOB-001-01';
    // const barcode = 'PAL0000036'
    dispatch(
      validateScan({
        type: 'warehouse-flows',
        value: barcode,
        expectedValue: 'null',
        restockStep: 'null'
      })
    );
  };

  const handleFakeScanDest = () => {
    // const barcode = 'PAL0000036';
    const barcode = 'PAR-PROD-B';
    // const barcode = 'PAR-DOS-004';
    // const barcode = 'PAR-FL-004'
    // const barcode = 'error';
    dispatch(
      validateScan({
        type: 'warehouse-flows',
        value: barcode,
        expectedValue: 'null',
        restockStep: 'null'
      })
    );
  };

  const endflow = () => {
    dispatch(toggleModal(true));

    setTimeout(() => {
      resetState();
      dispatch(resetWarehouseFlows());
      dispatch(toggleModal(false));
    }, 2000);
  };

  const handleValidate = async () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (
      warehouseFlowStep === 'pallet_to_dosing_to_validate' ||
      warehouseFlowStep === 'pallet_to_stock_to_validate'
    ) {
      const res = await dispatch(
        movePallet({
          employeeId: user.id,
          palletBarcode: storedBarcode,
          destBarcode
        })
      );

      if (res.payload?.result.http_status === 200) {
        endflow();
      } else {
        setHorreoError({
          errorText: 'views.warehouse_flows.horreo_error_move_pallet',
          secondaryErrorText: res.payload.result.error_message,
          hasError: true
        });
      }
    }
    if (warehouseFlowStep === 'pcb-selected') {
      const res = await dispatch(
        movePackaging({
          employeeId: user.id,
          sourcePackage: storedBarcode,
          destBarcode,
          pcbQty: selectedQty,
          packagingId: selectedPcb.pcbId
        })
      );

      if (res.payload?.http_status === 200) {
        endflow();
      } else {
        setHorreoError({
          errorText: 'views.warehouse_flows.horreo_error_move_packaging',
          secondaryErrorText: res.payload.result.error_message,
          hasError: true
        });
      }
    }
    if (warehouseFlowStep === 'mob_to_prod_to_validate') {
      const res = await dispatch(
        supplyProdline({
          employeeId: user.id,
          sourceBarcode: storedBarcode,
          destBarcode,
          qty: selectedQty
        })
      );

      if (res.payload?.http_status === 200) {
        endflow();
      } else {
        setHorreoError({
          errorText: 'views.warehouse_flows.horreo_error_supply_prodline',
          secondaryErrorText: res.payload,
          hasError: true
        });
      }
    }
    if (warehouseFlowStep === 'pcb-selected-pallet') {
      const res = await dispatch(
        fillPackage({
          employeeId: user.id,
          sourcePackage: storedBarcode,
          destBarcode,
          pcbQty: selectedQty,
          packagingId: selectedPcb ? selectedPcb.pcbId : dosingPcbData.id
        })
      );

      if (res.payload?.http_status === 200) {
        endflow();
      } else {
        setHorreoError({
          errorText: 'views.warehouse_flows.horreo_error_fill_package',
          secondaryErrorText: res.payload.result.error_message,
          hasError: true
        });
      }
    }
  };

  const handleSelect = () => {
    dispatch(
      updateWarehouseFlowStep({
        warehouseFlowStep:
          warehouseFlowStep === 'select-pcb-pallet'
            ? 'pcb-selected-pallet'
            : 'pcb-selected'
      })
    );
    dispatch(toggleModal(false));
  };

  const modalContent = () => {
    if (isLoading) return <Loader />;
    if (
      warehouseFlowStep === 'select-pcb' ||
      warehouseFlowStep === 'select-pcb-pallet'
    ) {
      return (
        <div className="pcb-selector-wrapper">
          <h1 className="pcb-modal-title">Choisir le contenant</h1>
          <div className="pcbs-wrapper">
            {dosingPcbData.map((item, index) => (
              <div
                className={`pcb-element ${
                  selectedPcb?.index === index ? 'is-selected' : ''
                }`}
                key={index}
                onClick={() => setSelectedPcb({ index, pcbId: item.id })}
              >
                {item.name}
              </div>
            ))}
          </div>
          <div
            className={`validate-button pcb-button ${
              selectedPcb === null && 'validate-button__disabled'
            }`}
            onClick={selectedPcb ? handleSelect : null}
          >
            Valider
          </div>
        </div>
      );
    }
    if (
      warehouseFlowStep === 'pcb-selected' ||
      warehouseFlowStep === 'pcb-selected-pallet' ||
      warehouseFlowStep === 'mob_to_prod_to_validate'
    ) {
      return (
        <div className="flow-confirmation-modal">
          <div>
            {t('views.warehouse_flows_transfert_validated')} <br />
            <div className="flow-confirmation-modal-text-wrapper">
              {selectedQty} {` contenant${selectedQty > 1 ? 's' : ''} vers`}
              <span className="modal-text-bold">{destBarcode}</span>
            </div>
          </div>
          <img src={validCheck} alt="valid checkmark" />
        </div>
      );
    }
    return (
      <div className="flow-confirmation-modal">
        {t('views.warehouse_flows_transfert_validated')}{' '}
        <img src={validCheck} alt="valid checkmark" />
      </div>
    );
  };

  const renderStepTitle = () => <h1 className="step-title">{stepTitle()}</h1>;
  const renderFakeScanButtons = () => {
    if (warehouseFlowStep === '')
      return (
        <div onClick={handleFakeScan} style={{ marginTop: '1em' }}>
          fakescan
        </div>
      );
    if (
      warehouseFlowStep === 'pallet-scanned' ||
      warehouseFlowStep === 'mob-scanned'
    )
      return (
        <div onClick={handleFakeScanDest} style={{ marginTop: '1em' }}>
          fakescan
        </div>
      );
    return null;
  };

  const renderInfoContainer = () => {
    if (
      warehouseFlowStep === 'mob-scanned' ||
      warehouseFlowStep === 'mob_to_prod_to_validate'
    )
      return (
        <div className={`info-container ${background('mob')}`}>
          <span className="bold">{storedBarcode}</span>
        </div>
      );

    if (warehouseFlowStep !== '') {
      return (
        <div className={`info-container ${background('pallet')}`}>
          <span className="bold">{storedBarcode}</span>
          {productName} <br /> {qty && `${qty} kg`}
        </div>
      );
    }
    return null;
  };

  const renderBarcodeScannerOrInfo = () => {
    if (
      warehouseFlowStep === '' ||
      warehouseFlowStep === 'pallet-scanned' ||
      warehouseFlowStep === 'mob-scanned'
    ) {
      return (
        <img
          src={barcodeImg}
          className={`barcode-scanner ${
            warehouseFlowStep === '' ? '' : 'in-step'
          }`}
          alt="barcode scanner"
        />
      );
    }
    if (
      warehouseFlowStep === 'pcb-selected' ||
      warehouseFlowStep === 'select-pcb' ||
      warehouseFlowStep === 'mob_to_prod_to_validate' ||
      warehouseFlowStep === 'pallet_to_stock_to_validate' ||
      warehouseFlowStep === 'pcb-selected-pallet' ||
      warehouseFlowStep === 'pallet_to_dosing_to_validate'
    ) {
      const bgType = () => {
        if (
          warehouseFlowStep === 'select-pcb' ||
          warehouseFlowStep === 'pcb-selected'
        )
          return 'mob';
        if (warehouseFlowStep === 'pallet_to_stock_to_validate') return 'stock';
        if (warehouseFlowStep === 'pallet-scanned') return 'dosing';
        if (warehouseFlowStep === 'pcb-selected-pallet') return 'pallet';
        if (warehouseFlowStep === 'pallet_to_dosing_to_validate')
          return 'dosing';
        return 'prod';
      };
      return (
        <div className={`info-container ${background(bgType())}`}>
          {warehouseFlowStep === 'mob_to_prod_to_validate' &&
            'Chaîne de production'}
          {warehouseFlowStep === 'pallet_to_dosing_to_validate' &&
            'Atelier de dosage'}
          {warehouseFlowStep === 'pallet_to_stock_to_validate' &&
            'Zone de stockage'}
          {(warehouseFlowStep === 'pallet_to_dosing_to_validate' ||
            warehouseFlowStep === 'pallet_to_stock_to_validate') && <br />}
          <span className="bold">{destBarcode}</span>
        </div>
      );
    }
    if (warehouseFlowStep === 'pallet-scanned') {
      return (
        <div className={`info-container ${background('dosing')}`}>
          Atelier de dosage <br />
          {destBarcode}
        </div>
      );
    }
    return null;
  };

  const renderInstructionsOrValidateButton = () => {
    if (
      instructionText &&
      warehouseFlowStep !== 'pallet_to_dosing_to_validate' &&
      warehouseFlowStep !== 'pcb-selected' &&
      warehouseFlowStep !== 'mob_to_prod_to_validate' &&
      warehouseFlowStep !== 'pallet_to_stock_to_validate' &&
      warehouseFlowStep !== 'pcb-selected-pallet'
    ) {
      return (
        <div className="movement-instruction">
          {instructions()} <br />
          <div className="movement-instruction-bold">{boldInstructions()}</div>
        </div>
      );
    }
    return (
      <div
        className={`validate-button ${
          (warehouseFlowStep === 'pcb-selected' && selectedQty === null) ||
          (warehouseFlowStep === 'mob_to_prod_to_validate' &&
            selectedQty === null) ||
          (warehouseFlowStep === 'pcb-selected-pallet' && selectedQty === null)
            ? 'validate-button__disabled'
            : ''
        }`}
        onClick={handleValidate}
      >
        Valider Transfert
      </div>
    );
  };
  useEffect(() => {
    if (
      (warehouseFlowStep === 'select-pcb' && isScanValid) ||
      (warehouseFlowStep === 'select-pcb' && isScanValid === undefined) ||
      (warehouseFlowStep === 'select-pcb-pallet' && isScanValid) ||
      (warehouseFlowStep === 'select-pcb-pallet' && isScanValid === undefined)
    )
      dispatch(toggleModal(true));
  }, [warehouseFlowStep]);

  const qtys = () => Array.from({ length: 18 }, (_, index) => index + 1);

  const renderPcbSelectionSection = () => {
    if (
      warehouseFlowStep === 'pcb-selected' ||
      warehouseFlowStep === 'mob_to_prod_to_validate' ||
      warehouseFlowStep === 'pcb-selected-pallet'
    ) {
      return (
        <div className="pcb-qty-selector-wrapper">
          Remplir le nombre de contenants déplacés
          <div className="dropdown-wrapper">
            <DropdownAlt
              options={qtys()}
              selectedOption={selectedQty}
              onOptionSelect={handleOptionSelect}
            />{' '}
            {selectedPcb !== null
              ? dosingPcbData[selectedPcb?.index]?.name
              : dosingPcbData.name}
          </div>
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    if (isLoading) dispatch(toggleModal(true));
    else dispatch(toggleModal(false));
  }, [isLoading]);

  return (
    <div className="warehouse-flows-content-wrapper">
      {renderStepTitle()}
      {/* {renderFakeScanButtons()} */}
      {renderInfoContainer()}
      {warehouseFlowStep !== '' && (
        <img
          src={downArrow}
          className={`down-arrow ${
            warehouseFlowStep !== '' &&
            warehouseFlowStep !== 'pallet_to_stock_to_validate'
              ? 'down-arrow__small'
              : ''
          }`}
          alt="down arrow"
        />
      )}
      {renderBarcodeScannerOrInfo()}
      {renderPcbSelectionSection()}
      {renderInstructionsOrValidateButton()}
      <Modal>{modalContent()}</Modal>
      {isScanValid === false && (
        <FlashScreen
          autoClose={false}
          type="error"
          message={t(message)}
          secondaryMessage={secondaryMessage}
          onClick={() =>
            setHorreoError({
              errorText: '',
              secondaryErrorText: '',
              hasError: false
            })
          }
        />
      )}
      {horreoError.hasError === true && (
        <FlashScreen
          type="error"
          message={horreoError.errorText}
          secondaryMessage={horreoError.secondaryErrorText}
          autoClose={false}
          onClick={() =>
            setHorreoError({
              errorText: '',
              secondaryErrorText: '',
              hasError: false
            })
          }
        />
      )}
    </div>
  );
}

export default WarehouseFlowsContent;
