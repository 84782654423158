import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button } from '@/components';
import { toggleModal } from '@/redux/modal/modal.slice';

const PreviousProductBanner = ({ product, warning }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { productName, destinationNames } = product;

  console.log({ warning });

  return (
    <div className="v-previous-product">
      <div className="v-previous-product__header">
        {!warning ? (
          <>
            {t('views.previous_product_banner.header_1')}:{' '}
            <span className="v-previous-product__title">{productName}</span>{' '}
            {t('views.previous_product_banner.header_2')}{' '}
            <span className="v-previous-product__location">
              {destinationNames[0]}
            </span>
          </>
        ) : (
          <>
            {t('views.previous_product_banner.header_1')}:{' '}
            <span className="v-previous-product__title">
              {t('views.previous_product_banner.header_2_missing_product')} (
              {productName})
            </span>
          </>
        )}
      </div>
      <div className="v-previous-product__cancel-btn">
        <Button
          isPrimary
          title={
            // !warning
            // ? `Annuler dernier ajout de ${title}`
            // :
            t('views.previous_product_banner.cancel')
          }
          onClick={() => dispatch(toggleModal(true))}
        />
      </div>
    </div>
  );
};

export default PreviousProductBanner;
