import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as GreaterThanIcon } from '@/assets/chevron_right.svg';

function PrintLabelFaq() {
  const { t, i18n } = useTranslation();
  let faqItemsNumber = 0;

  for (
    let i = 0;
    i18n.exists(`views.print_label.faq_item_${i + 1}_title`);
    i += 1
  ) {
    faqItemsNumber += 1;
  }

  const generateFaqItems = () => {
    const faqItems = [];
    for (let index = 0; index < faqItemsNumber; index += 1) {
      faqItems.push(
        <Link to={index.toString()}>
          <div className="faq-item">
            <p>{t(`views.print_label.faq_item_${index + 1}_title`)}</p>
            <GreaterThanIcon />
          </div>
        </Link>
      );
    }
    return faqItems;
  };
  return (
    <div className="faq-container">
      <h1>FAQ</h1>
      {generateFaqItems()}
    </div>
  );
}

export default PrintLabelFaq;
