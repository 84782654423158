export const sortProductsByLocation = (products) => {
  if (products && Array.isArray(products)) {
    return products.sort((p_far, p_near) => {
      if (p_far.location > p_near.location) return 1;
      if (p_far.location < p_near.location) return -1;
      return 0;
    });
  }
  return null;
};

export const isEmpty = (obj) =>
  obj &&
  Object.keys(obj).length === 0 &&
  Object.getPrototypeOf(obj) === Object.prototype;

export const resetLocalStorage = (userID) => {
  localStorage.removeItem(`batchState_${userID}`);
  localStorage.removeItem(`productIndex_${userID}`);
  localStorage.removeItem(`restockStep_${userID}`);
  localStorage.removeItem(`warehouseFlowStep_${userID}`);
  localStorage.removeItem(`productsMag_${userID}`);
  localStorage.removeItem(`productsStored_${userID}`);
};
