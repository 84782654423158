import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { selectUser } from '@/redux/user/user.slice';
import { getBatchs } from '@/redux/restock/restock.actions';
import { Loader } from '@/components';

function Restock() {
  const { id: userID } = useSelector(selectUser);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { batchs } = useSelector((state) => state.restock);

  useEffect(() => {
    if (userID) dispatch(getBatchs(userID));
  }, [userID]);

  const resolveDisabled = (batch) => {
    if (batch.state === 'draft' || !batch.in_treatment) {
      return false;
    }
    if (userID !== batch.employee_id) {
      return true;
    }
    return false;
  };

  return (
    <div className="u-page-center">
      <h4>{t('views.restock.batch_list')}</h4>
      <div className="batch-list-wrapper">
        {batchs.status === 'succeeded' ? (
          batchs?.data?.map((batch) => (
            <div className="c-select-mode__content" key={batch.id}>
              <button
                type="button"
                className={`c-select-option__item batch-item ${
                  userID === batch.employee_id && 'started'
                }`}
                key={batch.id}
                disabled={resolveDisabled(batch)}
              >
                {userID === batch.employee_id ||
                batch.state === 'draft' ||
                !batch.in_treatment ? (
                  <Link
                    to={batch.id.toString()}
                    onClick={() => dispatch(getBatchs(userID))}
                  >
                    {t('views.restock.batch')} {batch.id}{' '}
                    {t(`views.restock.batch_state.${batch.state}`)}{' '}
                  </Link>
                ) : (
                  <span>
                    {t('views.restock.batch')} {batch.id}{' '}
                    {t(`views.restock.batch_state.${batch.state}`)}{' '}
                  </span>
                )}
              </button>
            </div>
          ))
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
}

export default Restock;
