/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import ProductToScan from '../ProductToScan/ProductToScan';
import {
  resetSupplyProductsData,
  setMobileRack
} from '@/redux/supplyProducts/supplyProducts.slice';
import RackDetails from '../RackDetails/RackDetails';
import { isEmpty } from '@/helpers/helpers';
import PreviousProductBanner from '../PreviousProductBanner/PreviousProductBanner';
import CancelPreviousProductModal from '../CancelPreviousProductModal/CancelPreviousProductModal';
import { Button } from '@/components';
import { fetchSupplyProducts } from '@/redux/supplyProducts/supplyProducts.actions';
import { axios } from '@/helpers/api';
import { setRound } from '@/redux/round/round.slice';
import { selectIsLittleHub, selectUser } from '@/redux/user/user.slice';

const SupplyScanProduct = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { rack } = useParams();
  const { hubId } = useSelector(selectUser);
  const isLittleHub = useSelector(selectIsLittleHub);
  const {
    productToScan,
    previousScanned,
    error,
    mobileRack,
    totalToScan,
    totalScanned,
    missingProducts
  } = useSelector((state) => state.supplyProducts);
  const { selectedMode } = useSelector((state) => state.mode);
  const { selectedRound } = useSelector((state) => state.round);
  const { warning } = useSelector((state) => state.barcode);

  useEffect(() => {
    // Coming from rack scanning, we reset the scan to Undefined
    dispatch(fetchSupplyProducts(rack));

    async function getRackInfo() {
      // IN case we refressh the page => to get trip name
      const { data } = await axios.get(
        `/picking/hub/${hubId}/provision/status`
      );
      dispatch(setRound({ name: data?.[rack]?.trip }));
    }
    if (!mobileRack) {
      dispatch(setMobileRack(rack));

      getRackInfo();
    }
  }, []);

  const handleFullRackClick = () => {
    dispatch(resetSupplyProductsData());
    const url = `/${selectedMode.id}/${selectedRound.id}`;
    navigate(url);
  };

  if (error) {
    return null;
  }

  const TripSupplyHasEndedView = !isLittleHub ? (
    <div className="p-supply-product__rack-full">
      <div>{t('views.supply_scan_product.rack_full')} ✅</div>
      <Button
        className="p-supply-product__navigate-btn"
        title="Changer de tournée"
        isPrimary
        onClick={handleFullRackClick}
      />
    </div>
  ) : (
    <div className="p-supply-product__rack-full">
      <div>{t('views.supply_scan_product.rack_full_little_hub')} ✅</div>
      <Button
        className="p-supply-product__navigate-btn"
        title="Retour au menu"
        isPrimary
        onClick={handleFullRackClick}
      />
    </div>
  );

  return (
    <div className="p-supply-product">
      {/* Banière pour le produit précédemment scanné */}

      {!isEmpty(previousScanned) && (
        <div className="p-supply-product__previous-product-banner">
          <PreviousProductBanner product={previousScanned} warning={warning} />
          <CancelPreviousProductModal
            product={previousScanned}
            warning={warning}
          />
        </div>
      )}

      {/* Produit à scanner */}

      {!isEmpty(productToScan) ? (
        <div className="p-supply-product__product-to-scan">
          <ProductToScan product={productToScan} />
        </div>
      ) : totalToScan > 0 && totalToScan === totalScanned + missingProducts ? (
        <div className="p-supply-product__rack-full">
          <div>
            {t('views.supply_scan_product.product_to_scan_text_1')} ✅
            <br />
            {t('views.supply_scan_product.product_to_scan_text_2')}
          </div>
          <Button
            className="p-supply-product__navigate-btn"
            title="Retour au menu"
            isPrimary
            onClick={handleFullRackClick}
          />
        </div>
      ) : (
        TripSupplyHasEndedView
      )}

      {/* Info sur le rack et la tournée */}
      <div className="p-supply-product__rack-details">
        <RackDetails />
      </div>
    </div>
  );
};

export default SupplyScanProduct;
