import { useState } from 'react';
import i18n from 'i18next';
import { useSelector } from 'react-redux';
import PinInputGrid from './PinInputGrid';
import { signIn } from '@/__mock__/validatePin';
import HUBS, { BIG_HUB_KEY } from '@/constants/hubs';

const PIN_LENGTH = 5;

const PinInput = ({ id, idInputValue, hubId }) => {
  const [pin, setPin] = useState(new Array(PIN_LENGTH).fill(''));

  const { usersByHub } = useSelector((state) => state.user);

  const onPinChange = (value, index) => {
    const newPin = [...pin];
    newPin[index] = value;
    setPin(newPin);
  };

  const resetPin = () => {
    setPin(new Array(PIN_LENGTH).fill(''));
  };

  const validatePin = async () => {
    const pinToValidate = pin.join('');
    try {
      let user = await signIn(usersByHub, Number(id), pinToValidate);
      const userHub = HUBS.find((hub) => hub.id === hubId);
      user = {
        // TODO to change with user.is_brouette_admin
        isAdmin: true,
        fake: false,
        hubKind: userHub.hubKind,
        country: userHub.country,
        hubId,
        ...user
      };

      localStorage.setItem('user', JSON.stringify(user));
      if (user.country === 'es') i18n.changeLanguage('es-ES');
      window.location.replace('/');
    } catch (_e) {
      resetPin();
    }
  };
  return (
    <PinInputGrid
      idInputValue={idInputValue}
      pinLength={PIN_LENGTH}
      pin={pin}
      onPinChange={onPinChange}
      validatePin={validatePin}
      resetPin={resetPin}
    />
  );
};

export default PinInput;
