/* eslint-disable no-param-reassign */
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { LITTLE_HUB_KEY } from '@/constants/hubs';
import getUsersByHub from '@/redux/user/user.actions';

const initialState = {
  data: {},
  usersByHub: null,
  status: 'idle',
  error: null
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserFromSession: (state, action) => {
      state.data = { ...action.payload };
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getUsersByHub.rejected, (state, action) => {
        state.error = action.payload;
        state.status = 'failed';
        state.usersByHub = null;
      })
      .addCase(getUsersByHub.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getUsersByHub.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = null;
        state.usersByHub = action.payload;
      });
  }
});

export const selectUser = createSelector(
  (state) => state.user.data,
  (user) => user
);

export const selectIsLittleHub = createSelector(
  (state) => state.user.data,
  (user) => user.hubKind === LITTLE_HUB_KEY
);

export const { setUserFromSession } = userSlice.actions;

export default userSlice.reducer;
