import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import {
  SignInPage,
  SelectMode,
  SelectRound,
  ScanRack,
  SupplyScanProduct,
  FillScanProduct,
  AdminPage,
  ActivateTripPage,
  ResetRack,
  PrintLabel,
  SettingsPage,
  EnvironmentPage
} from '@/views';
import { WithHeaderLayout, Layout } from '@/components';
import { UserProvider } from './contexts/UserContext';
import store from '@/redux/store';
import ScanBasket from './views/FillScanProduct/ScanBasket/ScanBasket';
import PrintLabelFaq from './views/Print/PrintLabelFaq/PrintLabelFaq';
import PrintLabelFaqElement from './views/Print/PrintLabelFaqElement/PrintLabelFaqElement';
import WarehouseFlows from './views/WarehouseFlows/WarehouseFlows/WarehouseFlows';
import Restock from './views/Restock/Restock/Restock';
import BatchDetails from './views/Restock/BatchDetails/BatchDetails';

const App = () => (
  <Router>
    <ReduxProvider store={store}>
      <UserProvider>
        <Layout>
          <Routes>
            <Route path="/signin" element={<SignInPage />} />
            <Route element={<WithHeaderLayout />}>
              <Route exact path="/" element={<SelectMode />} />
              {/* Supply mode routes */}
              <Route exact path="/supply" element={<SelectRound />} />
              <Route exact path="/supply/:round" element={<ScanRack />} />
              <Route
                exact
                path="/supply/:round/:rack"
                element={<SupplyScanProduct />}
              />

              {/* Fill baskets mode routes */}
              <Route exact path="/fill" element={<ScanBasket />} />
              <Route
                exact
                path="/fill/:packageNumber"
                element={<FillScanProduct />}
              />

              {/* Print label routes */}
              <Route exact path="/print" element={<PrintLabel />} />
              <Route exact path="/print/faq" element={<PrintLabelFaq />} />
              <Route
                exact
                path="/print/faq/:item"
                element={<PrintLabelFaqElement />}
              />

              {/* Restock mode routes */}
              <Route exact path="/restock" element={<Restock />} />
              <Route
                exact
                path="/restock/:batchId"
                element={<BatchDetails />}
              />

              {/* Warehouse flows mode routes */}
              <Route
                exact
                path="/warehouseflows"
                element={<WarehouseFlows />}
              />

              {/* Admin routes */}
              <Route exact path="/admin" element={<AdminPage />} />
              <Route exact path="/settings" element={<SettingsPage />} />
              <Route
                exact
                path="/settings/environment"
                element={<EnvironmentPage />}
              />
              <Route
                exact
                path="/admin/active-trip"
                element={<ActivateTripPage />}
              />
              <Route exact path="/admin/reset-mob" element={<ResetRack />} />
            </Route>
          </Routes>
        </Layout>
      </UserProvider>
    </ReduxProvider>
  </Router>
);

export default App;
