/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useEffect, useRef } from 'react';

const BACKSPACE_KEY_CODE = 8;

const PinInputGrid = ({
  idInputValue,
  pinLength,
  pin,
  onPinChange,
  validatePin,
  resetPin
}) => {
  const inputsRef = useRef([]);

  const changePinFocus = (pinIndex) => {
    const ref = inputsRef.current[pinIndex];
    if (ref) {
      ref.focus();
    }
  };

  useEffect(() => {
    if (pin[pinLength - 1] !== '') {
      validatePin();
    }
    if (pin[0] === '' && idInputValue !== '') {
      changePinFocus(0);
    }
  }, [pin]);

  const initRef = (el, index) => {
    if (el) {
      inputsRef.current[index] = el;
    }
  };

  const handleChange = (e, index) => {
    const { value } = e.target;
    const pinNumber = Number(value.trim());
    console.log(e);
    if (Number.isNaN(pinNumber) || value.length === 0) {
      return;
    }

    if (value.length === 1) {
      onPinChange(pinNumber, index);
      if (index < pinLength - 1) {
        changePinFocus(index + 1);
      }
    }
  };

  const onKeyDown = ({ keyCode }, index) => {
    if (keyCode !== BACKSPACE_KEY_CODE) {
      return;
    }
    if (pin[index] !== '') {
      onPinChange('', index);
    } else if (index !== 0) {
      onPinChange('', index - 1);
      changePinFocus(index - 1);
    }
  };

  const inputs = Array.from({ length: pinLength }, (_, index) => {
    const key = `pin-input-${index}`;

    return (
      <input
        placeholder=""
        className="c-pin-grid__input"
        key={key}
        id={key}
        ref={(el) => initRef(el, index)}
        onChange={(e) => handleChange(e, index)}
        onKeyUp={(e) => onKeyDown(e, index)}
        value={pin[index]}
        pattern="[0-9]{1}"
        required
        type="number"
      />
    );
  });

  return (
    <div className="c-pin-container">
      <label>PIN</label>
      <div className="c-pin-grid">{inputs}</div>
      <button
        className="c-pin-clear-btn"
        type="button"
        onClick={() => {
          resetPin();
          changePinFocus(0);
        }}
      >
        x
      </button>
    </div>
  );
};

export default PinInputGrid;
