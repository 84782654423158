/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    toggleModal: (state, { payload }) => {
      state.isOpen = payload;
    }
  }
});

export const { toggleModal } = modalSlice.actions;

export default modalSlice.reducer;
