const { useState, useRef } = require('react');

export default function DropdownAlt({
  options,
  selectedOption,
  onOptionSelect
}) {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (option) => {
    onOptionSelect(option);
    setIsOpen(false);
  };

  return (
    <div className="dropdownAlt">
      <button
        className="dropdown-button"
        type="button"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        ▼{selectedOption || '-'}
      </button>
      {isOpen && (
        <div className="dropdown-options">
          {options.map((option, index) => (
            <div key={index} onClick={() => handleClick(option)}>
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
