/* eslint-disable no-nested-ternary */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from '@/components';
import { toggleModal } from '@/redux/modal/modal.slice';

const TripDetailsModal = ({ trip, handleModalClose }) => {
  const { t } = useTranslation();

  const {
    name,
    status,
    pickingStatus,
    finishedPackages,
    provisionedPackages,
    totalPackages
  } = trip;
  const dispatch = useDispatch();

  const handleUnderstoodClick = () => {
    handleModalClose();
    dispatch(toggleModal(false));
  };
  return (
    <Modal>
      <div className="v-trip-modal">
        <div className="v-trip-modal__header">
          {t('views.trip_details_modal.header')}
          <br />
          <span className="v-trip-modal__name">{name}</span>
        </div>
        <div className="v-trip-modal__detail">
          <div>
            {t('views.trip_details_modal.detail')} ?:{' '}
            <span className="v-trip-modal__name">
              {status !== 'created'
                ? t('views.trip_details_modal.detail_yes')
                : t('views.trip_details_modal.detail_no')}
            </span>
          </div>
          {status !== 'created' && (
            <div>
              {t('views.trip_details_modal.status')}:{' '}
              <span className="v-trip-modal__name">
                {pickingStatus === 'created'
                  ? `❌ ${t('views.trip_details_modal.not_started')}`
                  : pickingStatus === 'started'
                  ? `🔍 ${t('views.trip_details_modal.is_being_prepared')}`
                  : `✅ ${t('views.trip_details_modal.finished')}`}
              </span>
            </div>
          )}

          {/* <div>
            Total paniers à préparer:{' '}
            <span className="v-trip-modal__name">{totalPackages}</span>
          </div> */}
          <div>
            {t('views.trip_details_modal.baskets_provided')}:{' '}
            <span className="v-trip-modal__name">
              {provisionedPackages}/{totalPackages}{' '}
              {provisionedPackages === totalPackages ? '✅' : ''}
            </span>
          </div>
          <div>
            {t('views.trip_details_modal.baskets_prepared')}:{' '}
            <span className="v-trip-modal__name">
              {finishedPackages}/{totalPackages}
            </span>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            className="v-trip-modal__ok-btn"
            title="OK"
            onClick={handleUnderstoodClick}
            isPrimary
          />
        </div>
      </div>
    </Modal>
  );
};

export default TripDetailsModal;
