import React from 'react';
import { SelectOption } from '@/components';

const SETTINGS_OPTIONS = [
  {
    id: 'environment',
    name: 'Environnement'
  }
];
function SettingsPage() {
  return (
    <div className="u-page-center">
      <div className="c-select-mode__content">
        {SETTINGS_OPTIONS.map((option) => (
          <SelectOption
            option={option}
            link={option.id}
            //   name={t(`views.select_mode.${option.name}`)}
            name={option.name}
            //   onSelect={handleModeSelection}
          />
        ))}
      </div>
    </div>
  );
}

export default SettingsPage;
