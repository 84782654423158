import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { OutlinedInput } from '@mui/material';
import Select from '@mui/material/Select';

export default function BasicSelect({
  inputLabel,
  options,
  selectedValue,
  onSelect
}) {
  const handleChange = (event) => {
    onSelect(event.target.value);
  };

  if (!options) return null;

  return (
    <Box sx={{ minWidth: 250 }}>
      <FormControl fullWidth size="small">
        <InputLabel id="dropdown">{inputLabel}</InputLabel>
        <Select
          labelId="dropdown"
          id="select"
          value={selectedValue}
          label="Age"
          onChange={handleChange}
          input={
            <OutlinedInput id={`select-${inputLabel}`} label={inputLabel} />
          }
          MenuProps={{
            style: {
              maxHeight: 400
            }
          }}
        >
          {options.map(
            (option) =>
              option && <MenuItem value={option.value}>{option.label}</MenuItem>
          )}
        </Select>
      </FormControl>
    </Box>
  );
}
