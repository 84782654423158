/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { axios } from '@/helpers/api';

export const getBasket = createAsyncThunk(
  'baskets/getBasket',
  async ({ value }) => {
    try {
      const { data } = await axios.get(
        `/picking/package/${value}/shipping/details`
      );

      return data;
    } catch (error) {
      console.error(error.message);
      return null;
    }
  }
);
