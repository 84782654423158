import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { resetIsScanValid, resetWarning } from '@/redux/barcode/barcode.slice';

const FlashScreen = ({
  message,
  secondaryMessage,
  type,
  autoClose,
  onClick
}) => {
  const [showComponent, setShowComponent] = useState(true);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const styles = clsx(
    'c-flash-screen',
    `c-flash-screen--${type}`,
    `${autoClose === false && 'no-auto-close'}`
  );

  const handleClose = () => {
    setShowComponent(false);
    if (autoClose === false) onClick();
    if (type === 'error') {
      dispatch(resetIsScanValid());
      dispatch(resetWarning());
    }
  };

  useEffect(() => {
    if ((autoClose === undefined || autoClose) && showComponent) {
      const toRef = setTimeout(() => {
        handleClose();
        clearTimeout(toRef);
      }, 3000);
    }
  }, [showComponent, autoClose]);

  console.log('window error rendered', showComponent, secondaryMessage);

  return showComponent ? (
    <>
      <div className="c-flash-screen-overlay" />
      <div className={styles}>
        <span>{message}</span>
        <span className="secondary-error-message">{secondaryMessage}</span>
        {autoClose === false && (
          <button
            type="button"
            className="flash-screen-button"
            onClick={handleClose}
          >
            {t('views.flashscreen.close')}
          </button>
        )}
      </div>
    </>
  ) : null;
};

export default FlashScreen;
