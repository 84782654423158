import { createSlice } from '@reduxjs/toolkit';
import { getBasket } from '@/redux/baskets/baskets.actions';

const initialState = {
  data: {}
};

const basketsSlice = createSlice({
  name: 'baskets',
  initialState,
  reducers: {
    resetBasket: (state) => {
      state.data = {};
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getBasket.fulfilled, (state, { payload }) => {
        state.data = payload;
      })
      .addCase(getBasket.rejected, (state, action) => {
        state.data = {};
      });
  }
});

export const { resetBasket } = basketsSlice.actions;

export default basketsSlice.reducer;
