/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const MODES = [
  {
    icon: '🛒',
    id: 'supply',
    name: 'grocery',
    isVisible: true
  },
  { icon: '🔎', id: 'fill', name: 'basket', isVisible: true },
  { icon: '🖨️', id: 'print', name: 'print_label', isVisible: true },
  { icon: '🛠', id: 'settings', name: 'settings', isVisible: true },
  {
    icon: '🔑',
    id: 'admin',
    name: 'admin',
    isVisible: false
  },
  { icon: '📝', id: 'restock', name: 'restock', isVisible: true },
  { icon: '🔄', id: 'warehouseflows', name: 'warehouse_flows', isVisible: true }
];

const initialState = {
  data: MODES,
  selectedMode: window.location.pathname.includes('supply')
    ? MODES[0]
    : window.location.pathname.includes('fill')
    ? MODES[1]
    : window.location.pathname.includes('print')
    ? MODES[2]
    : window.location.pathname.includes('settings')
    ? MODES[3]
    : window.location.pathname.includes('restock')
    ? MODES[4]
    : window.location.pathname.includes('warehouseflows')
    ? MODES[5]
    : MODES[0]
};

const modeSlice = createSlice({
  name: 'mode',
  initialState,
  reducers: {
    setMode: (state, { payload }) => {
      state.selectedMode = payload;
    }
  }
});

export const { setMode } = modeSlice.actions;

export default modeSlice.reducer;
