import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const style = {
  display: 'flex',
  justifyContent: 'center'
};

const Loader = () => (
  <div style={style}>
    <CircularProgress color="info" />
  </div>
);

export default Loader;
