/* eslint-disable no-promise-executor-return */
/* eslint-disable prefer-promise-reject-errors */

export const signIn = (usersByHub, id, pin) =>
  new Promise((resolve, reject) => {
    console.log({ id, pin });
    const user = usersByHub?.find((u) => u.id === id);
    if (user) {
      if (user.pin === pin) {
        resolve(user);
      } else {
        reject('Invalid User Pin');
      }
    } else {
      reject('Invalid User ID');
    }
  });

export const t = '';
