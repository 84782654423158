import { createAsyncThunk } from '@reduxjs/toolkit';
import { axios } from '@/helpers/api';

export const getGroceriesByPackage = createAsyncThunk(
  'fillProducts/getGroceriesByPackage',
  async (packageNumber, { dispatch, getState, rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `/picking/package/${packageNumber}/allocatedPlace`
      );

      return data;
    } catch (e) {
      console.log({ e });
      return rejectWithValue({
        status: e.response.status,
        reason: e.response.data
      });
    }
  }
);

export const validateAndTransferProduct = createAsyncThunk(
  'fillProducts/validateAndTransferProduct',
  async (value, { dispatch, getState, rejectWithValue }) => {
    const packageName = getState().fillProducts.data.package;
    try {
      const payload = {
        package: packageName,
        EAN13: value
      };

      await axios.put(`/picking/transfer/allocatedplace`, payload);

      return null;
    } catch (e) {
      console.log({ e });
      return rejectWithValue({
        status: e.response.status,
        reason: e.response.data
      });
    }
  }
);

export const validateAndTransferProductsList = createAsyncThunk(
  'fillProducts/validateAndTransferProductsList',
  async (value, { dispatch, getState, rejectWithValue }) => {
    const packageName = getState().fillProducts.data.package;
    const { products } = getState().fillProducts.data;

    try {
      const payload = products.map((product) => ({
        package: packageName,
        EAN13: product.EAN13
      }));

      await axios.put(`/picking/transfer/products/allocatedplace`, {
        products: payload
      });

      return null;
    } catch (e) {
      console.log({ e });
      return rejectWithValue({
        status: e.response.status,
        reason: e.response.data
      });
    }
  }
);
