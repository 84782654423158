import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosHorreo } from '@/helpers/api';

export const getBatchs = createAsyncThunk(
  'restock/getBatchs',
  async (userId, { __, ___, rejectWithValue }) => {
    try {
      const { data } = await axiosHorreo.post(`/api/stock/batchs/${userId}`);
      const { result } = data;

      if (result.http_status === 200) {
        return result.batchs;
      }

      return rejectWithValue('Batchs introuvables pour cet utilisateur');
    } catch (e) {
      console.log({ e });
      return rejectWithValue('Batchs introuvables pour cet utilisateur');
    }
  }
);

export const getBatch = createAsyncThunk(
  'restock/getBatch',
  async ({ batchId, employeeId }, { __, ___, rejectWithValue }) => {
    try {
      const { data } = await axiosHorreo.post(`/api/stock/batch/${batchId}`, {
        employee_id: employeeId
      });
      const { result } = data;

      // Api doesn't return http_status 200 here..
      if (!result.http_status) {
        result.move_lines.sort((a, b) => a.source_sequence - b.source_sequence);
        return result;
      }

      return rejectWithValue('Batch introuvable');
    } catch (e) {
      console.log({ e });
      return rejectWithValue('Batch introuvable');
    }
  }
);

export const updateMoveLine = createAsyncThunk(
  'restock/updateMoveLine',
  async (
    { moveLineId, employeeId, batchId, productId, qtyDone },
    { __, ___, rejectWithValue }
  ) => {
    try {
      const { data } = await axiosHorreo.post(
        `/api/stock/move_line/${moveLineId}`,
        {
          employee_id: employeeId,
          batch_id: batchId,
          product_id: productId,
          qty_done: qtyDone
        }
      );
      const { result } = data;

      // Api doesn't return http_status 200 here..
      if (result.http_status === 200) {
        return result;
      }

      return rejectWithValue('Impossible de mettre à jour le mouvement');
    } catch (e) {
      console.log({ e });
      return rejectWithValue('Impossible de mettre à jour le mouvement');
    }
  }
);

export const closeSession = createAsyncThunk(
  'restock/closeSession',
  async ({ batchId, employeeId }, { __, ___, rejectWithValue }) => {
    try {
      const { data } = await axiosHorreo.post(
        `/api/stock/batch/closed_session/${batchId}`,
        { employee_id: employeeId }
      );
      const { result } = data;

      if (result.http_status === 200) {
        return result.batchs;
      }

      return rejectWithValue('Impossible de cloturer le batch');
    } catch (e) {
      console.log({ e });
      return rejectWithValue('Impossible de cloturer le batch');
    }
  }
);

export const closeBatch = createAsyncThunk(
  'restock/closeBatch',
  async ({ batchId, employeeId }, { __, ___, rejectWithValue }) => {
    try {
      const { data } = await axiosHorreo.post(
        `/api/stock/batch/closed_batch/${batchId}`,
        { employee_id: employeeId }
      );
      const { result } = data;

      if (result.http_status === 200) {
        return result;
      }

      return rejectWithValue('Impossible de cloturer le batch');
    } catch (e) {
      console.log({ e });
      return rejectWithValue('Impossible de cloturer le batch');
    }
  }
);
